<template>
  <modal
      @close="$emit('close')"
      class="custom-popup link-email-popup"
  >
    <template slot="header">
      Link for Email
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row">
          <div class="custom-col">
            <DefaultInput
              :label="'Link on Product to Send on Email'"
              :value="text"
              :disabled="true"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="d-flex align-items-center flex-wrap">
        <MainButton
            :value="'Copy'"
            class="import-transaction__btn-i"
            @click.native="$emit('close')"
            :ico="true"
        >
          <template slot="ico">
            <CopyIcoWhite/>
          </template>
        </MainButton>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import CopyIcoWhite from "../../../../../../public/img/UI-group/copy-icon-white.svg?inline"

  export default {
    name: "LinkEmailPopup",
    components: {
      Modal,
      MainButton,
      DefaultInput,
      CopyIcoWhite
    },

    data(){
      return {
        text: 'https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif',
      }
    },

    mounted() {

    },

    methods: {
      changeImg(files) {
        this.files = files
      },
    }
  }

</script>

<style lang="scss">
  .link-email-popup{

    .modal-component__inner{
      max-width: 758px;
    }
  }

</style>
