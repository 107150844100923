
<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-if="$store.getters.getIsAdminRights === 'admin'"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_user',])"></div>
        <SearchSelect
            :options="options"
            :label="$t('common_user.localization_value.value')"
            :optionsLabel="'email.contacts[0]'"
            :functionSearch="functionSearchUserFilter"
            @change="changeUsersFilter"
            :selected="userName"
            :typeSelect="'users'"
            :userEmail="true"
        />
      </div>
<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['account_archive'])"></div>-->
<!--        <DefaultSelect-->
<!--            :label="$t('account_archive.localization_value.value')"-->
<!--            :options="optionsArchiveStatus"-->
<!--            :optionsLabel="'name'"-->
<!--            :selected="getAccountArchiveStatus()"-->
<!--            @change="changeAccountArchiveStatus"-->
<!--        />-->
<!--      </div>-->

      <template v-if="navTabs.all.active">
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_fullName',])"></div>
          <DefaultInput
              :label="$t('account_fullName.localization_value.value')"
              :type="'text'"
              v-model="fullName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_firstName',])"></div>
          <DefaultInput
              :label="$t('account_firstName.localization_value.value')"
              :type="'text'"
              v-model="firstName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_lastname',])"></div>
          <DefaultInput
              :label="$t('account_lastName.localization_value.value')"
              :type="'text'"
              v-model="lastName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_middleName',])"></div>
          <DefaultInput
              :label="$t('account_middleName.localization_value.value')"
              :type="'text'"
              v-model="middleName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_phone',])"></div>
          <DefaultInput
              :label="$t('common_phone.localization_value.value')"
              :type="'text'"
              v-model="phone"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_email',])"></div>
          <DefaultInput
              :label="$t('common_email.localization_value.value')"
              :type="'text'"
              v-model="email"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_cardNumber',])"></div>
          <DefaultInput
              :label="$t('account_cardNumber.localization_value.value')"
              :type="'text'"
              v-model="cardNumber"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_accountNumber',])"></div>
          <DefaultInput
              :label="$t('account_accountNumber.localization_value.value')"
              :type="'text'"
              v-model="accountNumber"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_usreou',])"></div>
          <DefaultInput
              :label="$t('account_usreou.localization_value.value')"
              :type="'text'"
              v-model="usreou"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_iBan',])"></div>
          <DefaultInput
              :label="$t('account_iBan.localization_value.value')"
              :type="'text'"
              v-model="iban"
          />
        </div>

        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_address',])"></div>
          <DefaultInput
              :label="$t('account_address.localization_value.value')"
              :type="'text'"
              v-model="address"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_city',])"></div>
          <DefaultInput
              :label="$t('account_city.localization_value.value')"
              :type="'text'"
              v-model="city"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_state',])"></div>
          <DefaultInput
              :label="$t('account_state.localization_value.value')"
              :type="'text'"
              v-model="state"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_correspondentBankName',])"></div>
          <DefaultInput
              :label="$t('account_correspondentBankName.localization_value.value')"
              :type="'text'"
              v-model="correspondentBankName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_correspondentBankSwift',])"></div>
          <DefaultInput
              :label="$t('account_correspondentBankSwift.localization_value.value')"
              :type="'text'"
              v-model="correspondentBankSwift"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_beneficiaryBankName',])"></div>
          <DefaultInput
              :label="$t('account_beneficiaryBankName.localization_value.value')"
              :type="'text'"
              v-model="beneficiaryBankName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_beneficiaryBankSwift',])"></div>
          <DefaultInput
              :label="$t('account_beneficiaryBankSwift.localization_value.value')"
              :type="'text'"
              v-model="beneficiaryBankSwift"
          />
        </div>

        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_cardIdNumber',])"></div>
          <DefaultInput
                  :label="$t('account_cardIdNumber.localization_value.value')"
                  :type="'text'"
                  v-model="cardId"
          />
        </div>
      </template>

      <template v-if="navTabs[ACCOUNT_BANK_TYPE.UKRAINIAN_BANK.name].active">
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_firstName',])"></div>
          <DefaultInput
              :label="$t('account_firstName.localization_value.value')"
              :type="'text'"
              v-model="firstName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_lastname',])"></div>
          <DefaultInput
              :label="$t('account_lastName.localization_value.value')"
              :type="'text'"
              v-model="lastName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_phone',])"></div>
          <DefaultInput
              :label="$t('common_phone.localization_value.value')"
              :type="'text'"
              v-model="phone"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_cardNumber',])"></div>
          <DefaultInput
              :label="$t('account_cardNumber.localization_value.value')"
              :type="'text'"
              v-model="cardNumber"
          />
        </div>
      </template>

      <template v-if="navTabs[ACCOUNT_BANK_TYPE.WORLD_BANK.name].active">
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_fullName',])"></div>
          <DefaultInput
              :label="$t('account_fullName.localization_value.value')"
              :type="'text'"
              v-model="fullName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_firstName',])"></div>
          <DefaultInput
              :label="$t('account_firstName.localization_value.value')"
              :type="'text'"
              v-model="firstName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_lastname',])"></div>
          <DefaultInput
              :label="$t('account_lastName.localization_value.value')"
              :type="'text'"
              v-model="lastName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_middleName',])"></div>
          <DefaultInput
              :label="$t('account_middleName.localization_value.value')"
              :type="'text'"
              v-model="middleName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_phone',])"></div>
          <DefaultInput
              :label="$t('common_phone.localization_value.value')"
              :type="'text'"
              v-model="phone"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_email',])"></div>
          <DefaultInput
              :label="$t('common_email.localization_value.value')"
              :type="'text'"
              v-model="email"
          />
        </div>

        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_cardNumber',])"></div>
          <DefaultInput
              :label="$t('account_cardNumber.localization_value.value')"
              :type="'text'"
              v-model="cardNumber"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_accountNumber',])"></div>
          <DefaultInput
              :label="$t('account_accountNumber.localization_value.value')"
              :type="'text'"
              v-model="accountNumber"
          />
        </div>
        <!--<div class="table-filter__item"-->
             <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
          <!--<div class="admin-edit" @click="editTranslate(['account_usreou',])"></div>-->
          <!--<DefaultInput-->
              <!--:label="$t('account_usreou.localization_value.value')"-->
              <!--:type="'text'"-->
              <!--v-model="usreou"-->
          <!--/>-->
        <!--</div>-->
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_iBan',])"></div>
          <DefaultInput
              :label="$t('account_iBan.localization_value.value')"
              :type="'text'"
              v-model="iban"
          />
        </div>

        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_address',])"></div>
          <DefaultInput
              :label="$t('account_address.localization_value.value')"
              :type="'text'"
              v-model="address"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_city',])"></div>
          <DefaultInput
              :label="$t('account_city.localization_value.value')"
              :type="'text'"
              v-model="city"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_state',])"></div>
          <DefaultInput
              :label="$t('account_state.localization_value.value')"
              :type="'text'"
              v-model="state"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_correspondentBankName',])"></div>
          <DefaultInput
              :label="$t('account_correspondentBankName.localization_value.value')"
              :type="'text'"
              v-model="correspondentBankName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_correspondentBankSwift',])"></div>
          <DefaultInput
              :label="$t('account_correspondentBankSwift.localization_value.value')"
              :type="'text'"
              v-model="correspondentBankSwift"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_beneficiaryBankName',])"></div>
          <DefaultInput
              :label="$t('account_beneficiaryBankName.localization_value.value')"
              :type="'text'"
              v-model="beneficiaryBankName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_beneficiaryBankSwift',])"></div>
          <DefaultInput
              :label="$t('account_beneficiaryBankSwift.localization_value.value')"
              :type="'text'"
              v-model="beneficiaryBankSwift"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_cardIdNumber',])"></div>
          <DefaultInput
              :label="$t('account_cardIdNumber.localization_value.value')"
              :type="'text'"
              v-model="cardId"
          />
        </div>
      </template>

      <template v-if="navTabs[ACCOUNT_BANK_TYPE.CUSTOM_BANK.name].active">
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_fullName',])"></div>
          <DefaultInput
              :label="$t('account_fullName.localization_value.value')"
              :type="'text'"
              v-model="fullName"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_usreou',])"></div>
          <DefaultInput
              :label="$t('account_usreou.localization_value.value')"
              :type="'text'"
              v-model="usreou"
          />
        </div>
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_iBan',])"></div>
          <DefaultInput
              :label="$t('account_iBan.localization_value.value')"
              :type="'text'"
              v-model="iban"
          />
        </div>
      </template>

      <template v-if="navTabs[ACCOUNT_BANK_TYPE.CUSTOM_ACCOUNT.name].active">
        <div class="table-filter__item"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_email',])"></div>
          <DefaultInput
              :label="$t('common_email.localization_value.value')"
              :type="'text'"
              v-model="email"
          />
        </div>
      </template>


<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['common_phone',])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('common_phone.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="phone"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['common_email',])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('common_email.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="email"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['account_cardNumber',])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('account_cardNumber.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="cardNumber"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['account_accountNumber',])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('account_accountNumber.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="accountNumber"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['account_usreou',])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('account_usreou.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="usreou"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['account_iBan',])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('account_iBan.localization_value.value')"-->
<!--            :type="'text'"-->
<!--            v-model="iban"-->
<!--        />-->
<!--      </div>-->
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  // import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import {ACCOUNT_BANK_TYPE} from "../../../../../../../staticData/staticVariables";

  export default {
    name: "AccountsFilter",
    components: {
      FilterBlock,
      MainButton,
      DefaultInput,
      // DefaultSelect,
      SearchSelect,
    },

    props: [
      'showFilter',
      'filterGetParams',
      'navTabs'
    ],

    mixins: [filterFunc, usersFunc],

    data(){
      return {
        ACCOUNT_BANK_TYPE: ACCOUNT_BANK_TYPE,

        optionsArchiveStatus: [],
        optionsArchiveStatusSelected: '',


        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        fullName: this.filterGetParams.fullName ? this.filterGetParams.fullName : '',
        firstName: this.filterGetParams.firstName ? this.filterGetParams.firstName : '',
        lastName: this.filterGetParams.lastName ? this.filterGetParams.lastName : '',
        middleName: this.filterGetParams.middleName ? this.filterGetParams.middleName : '',
        phone: this.filterGetParams.phone ? this.filterGetParams.phone : '',
        email: this.filterGetParams.email ? this.filterGetParams.email : '',
        cardNumber: this.filterGetParams.cardNumber ? this.filterGetParams.cardNumber : '',
        accountNumber: this.filterGetParams.accountNumber ? this.filterGetParams.accountNumber : '',
        usreou: this.filterGetParams.usreou ? this.filterGetParams.usreou : '',
        iban: this.filterGetParams.iban ? this.filterGetParams.iban : '',
        address: this.filterGetParams.address ? this.filterGetParams.address : '',
        city: this.filterGetParams.city ? this.filterGetParams.city : '',
        state: this.filterGetParams.state ? this.filterGetParams.state : '',
        correspondentBankName: this.filterGetParams.correspondentBankName ? this.filterGetParams.correspondentBankName : '',
        correspondentBankSwift: this.filterGetParams.correspondentBankSwift ? this.filterGetParams.correspondentBankSwift : '',
        beneficiaryBankName: this.filterGetParams.beneficiaryBankName ? this.filterGetParams.beneficiaryBankName : '',
        beneficiaryBankSwift: this.filterGetParams.beneficiaryBankSwift ? this.filterGetParams.beneficiaryBankSwift : '',
        cardId: this.filterGetParams.cardId ? this.filterGetParams.cardId : '',
        // inArchive: this.filterGetParams.inArchive ? this.filterGetParams.inArchive : '',

        options: [],

        filterCounts: [
          'id',
          'userId',
          'userName',
          'fullName',
          'firstName',
          'lastName',
          'middleName',
          'phone',
          'email',
          'cardNumber',
          'accountNumber',
          'usreou',
          'iban',
          'address',
          'city',
          'state',
          'correspondentBankName',
          'correspondentBankSwift',
          'beneficiaryBankName',
          'beneficiaryBankSwift',
          'cardId',
          // 'inArchive',
        ]
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.id = newVal.id ? newVal.id : ''
        this.userId = newVal.userId ? newVal.userId : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.fullName = newVal.fullName ? newVal.fullName : ''
        this.firstName = newVal.firstName ? newVal.firstName : ''
        this.lastName = newVal.lastName ? newVal.lastName : ''
        this.middleName = newVal.middleName ? newVal.middleName : ''
        this.phone = newVal.phone ? newVal.phone : ''
        this.email = newVal.email ? newVal.email : ''
        this.cardNumber = newVal.cardNumber ? newVal.cardNumber : ''
        this.accountNumber = newVal.accountNumber ? newVal.accountNumber : ''
        this.usreou = newVal.usreou ? newVal.usreou : ''
        this.iban = newVal.iban ? newVal.iban : ''
        this.address = newVal.address ? newVal.address : ''
        this.city = newVal.city ? newVal.city : ''
        this.state = newVal.state ? newVal.state : ''
        this.correspondentBankName = newVal.correspondentBankName ? newVal.correspondentBankName : ''
        this.correspondentBankSwift = newVal.correspondentBankSwift ? newVal.correspondentBankSwift : ''
        this.beneficiaryBankName = newVal.beneficiaryBankName ? newVal.beneficiaryBankName : ''
        this.beneficiaryBankSwift = newVal.beneficiaryBankSwift ? newVal.beneficiaryBankSwift : ''
        this.cardId = newVal.cardId ? newVal.cardId : ''
        // this.inArchive = newVal.inArchive ? newVal.inArchive : ''
      },

    },

    methods: {
      // getAccountArchiveStatus(){
      //   if(this.inArchive === '') return ''
      //   return this._.find(this.optionsArchiveStatus, {id: parseInt(this.inArchive)})
      // },
      //
      // changeAccountArchiveStatus(val){
      //   this.inArchive = val.id
      // },


      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)
        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        this.$emit('changeFilter', data)
      },

      changeShopTypesFilter(val) {
        this.shop = val.id
      },
    },
  }
</script>

<style scoped>

</style>
