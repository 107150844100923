var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"content-top-line content-top-line--with-bottom-line",class:{'admin-edit-item': _vm.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'account_allAccounts',
          'account_personalUkraine',
          'account_personalWorld',
          'account_business',
          'common_emails',
        ])}}}),_c('div',{staticClass:"content-tabs content-tabs--separator"},[_vm._l((_vm.navTabs),function(item,index){return [(item.permissionVisible)?_c('div',{key:index,staticClass:"content-tabs__item",class:{active: item.active},on:{"click":function($event){return _vm.$emit('changeTab', item.name)}}},[_c('div',{staticClass:"content-tabs__ico"},[_vm._v(" "+_vm._s(item.label)+" ")])]):_vm._e()]})],2),_c('div',{staticClass:"content-top-line-wrap"},[_c('ToggleFilterButton',{attrs:{"count":_vm.countFilterParams,"showFilter":_vm.showFilter},on:{"toggleFilter":function($event){return _vm.$emit('toggleFilter')}}})],1)]),_c('div',{staticClass:"light-bg-block"},[_c('div',{staticClass:"btn-right-block"},[_c('div',{class:{'admin-edit-item': _vm.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['account_newAccount'])}}}),_c('div',[_c('MainButton',{staticClass:"btn-fit-content ml-3",attrs:{"value":_vm.$t('account_newAccount.localization_value.value'),"ico":'ico'},nativeOn:{"click":function($event){return _vm.changeisModalAccountTypePopup(true)}}},[_c('template',{slot:"ico"},[_c('PlusIconSVG',{staticClass:"btn-icon-plus"})],1)],2)],1)])])]),(_vm.isModalAccountType)?_c('ChooseAccountTypePopup',{on:{"close":function($event){return _vm.changeisModalAccountTypePopup(false)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }