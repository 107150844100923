<template>
  <div class="fragment">

    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'account_allAccounts',
          'account_personalUkraine',
          'account_personalWorld',
          'account_business',
          'common_emails',
        ])"></div>
      <div class="content-tabs content-tabs--separator">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-if="item.permissionVisible"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
<!--              {{item.id}}  \\-->
              {{item.label}}
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton
            :count="countFilterParams"
            @toggleFilter="$emit('toggleFilter')"
            :showFilter="showFilter"
        />
      </div>
    </div>
    <div class="light-bg-block">
      <div class="btn-right-block">
        <div v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_newAccount'])"></div>
          <div>
            <MainButton class="btn-fit-content ml-3"
                        :value="$t('account_newAccount.localization_value.value')"
                        :ico="'ico'"
                        @click.native="changeisModalAccountTypePopup(true)"
            >
              <template slot="ico">
                <PlusIconSVG class="btn-icon-plus"/>
              </template>
            </MainButton>
          </div>
        </div>
      </div>
    </div>

    <ChooseAccountTypePopup
            v-if="isModalAccountType"
            @close="changeisModalAccountTypePopup(false)"
    />

  </div>
</template>


<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import ChooseAccountTypePopup from "../../../../popups/ChooseAccountTypePopup/ChooseAccountTypePopup";


  export default {
    name: "AccountsHead",
    components: {
      ChooseAccountTypePopup,
      ToggleFilterButton,
      MainButton,
      PlusIconSVG,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    data() {
      return {
        tab: this.activeTab,
        isModalAccountType: false,
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changePeriod(val) {
        this.activePeriod = val
      },

      changeisModalAccountTypePopup(val) {
        this.isModalAccountType = val
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";

  .light-bg-block {
    display: flex;
    flex-wrap: wrap;
  }

  @include for-992 {
    .btn-left-block {
      margin-bottom: 15px;
    }

    .btn-right-block {
      flex-wrap: wrap;
    }

  }

</style>

