<template>
  <div class="toggle-block__details-body">
    <div class="custom-row">
      <div class="custom-col custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_fullName'])"></div>
        <DefaultInput
            :label="$t('account_fullName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.fullName}"
            :error="account.validation.fullName"
            :errorTxt="$t(`${account.validationTranslate.fullName}.localization_value.value`)"
            v-model="account.data.fullName"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_email'])"></div>
<!--        Correspondent Bank Name-->
        <DefaultInput
            :label="$t('common_email.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.email}"
            :error="account.validation.email"
            :errorTxt="$t(`${account.validationTranslate.email}.localization_value.value`)"
            v-model="account.data.email"
        />
      </div>

    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "PayoneerSecond",

  components: {
    DefaultInput,
  },

  props: {
    account: Object,
  },

  mounted() {
    this.account.setFieldsValid({
      fullName: true,
      email: true,
    })
  },
}
</script>

<style scoped>

</style>