<template>
  <div class="order-create__section">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['account_personalInformation'])"></div>
      {{ $t('account_personalInformation.localization_value.value') }}
<!--      Personal Infromation-->
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--66 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_fullName'])"></div>
        <!--      Full Name (name, patronymic, surname)-->
        <DefaultInput
            :label="$t('account_fullName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.fullName}"
            :error="account.validation.fullName"
            :errorTxt="$t(`${account.validationTranslate.fullName}.localization_value.value`)"
            v-model="account.data.fullName"
        />
      </div>
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['account_accountDetails'])"></div>
      {{ $t('account_accountDetails.localization_value.value') }}
<!--      Account Details-->
    </div>

    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--66 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_bankName'])"></div>
        <DefaultInput
            :label="$t('account_bankName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.beneficiaryBankName}"
            :error="account.validation.beneficiaryBankName"
            :errorTxt="$t(`${account.validationTranslate.beneficiaryBankName}.localization_value.value`)"
            v-model="account.data.beneficiaryBankName"
        />
      </div>
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_usreou'])"></div>
        <DefaultInput
            :label="$t('account_usreou.localization_value.value')"
            :placeholder="'_ _ _ _ _ _ _ _ _ _'"
            v-mask="'##########'"
            v-bind:class="{'ui-no-valid': account.validation.usreou}"
            :error="account.validation.usreou"
            :errorTxt="$t(`${account.validationTranslate.usreou}.localization_value.value`)"
            v-model="account.data.usreou"
        />
      </div>
<!--      <div class="order-create__col custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-25 custom-col&#45;&#45;sm-50 mb-5"-->
<!--           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['account_MFOBank'])"></div>-->

<!--        <DefaultInput-->
<!--            :label="$t('account_MFOBank.localization_value.value')"-->
<!--            v-bind:class="{'ui-no-valid': account.validation.mfo}"-->
<!--            :error="account.validation.mfo"-->
<!--            :errorTxt="$t(`${account.validationTranslate.mfo}.localization_value.value`)"-->
<!--            v-model="account.data.mfo"-->
<!--        />-->
<!--      </div>-->
      <div class="order-create__col custom-col custom-col--33 custom-col--md-25 custom-col--sm-50 mb-5"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_iBan'])"></div>
<!--            v-mask="'AA ## ###### ##### #### #### ## ####'"-->
        <DefaultInput
            :label="$t('account_iBan.localization_value.value')"
            :placeholder="'_ _  _ _  _ _ _ _ _ _  _ _ _ _ _  _ _ _ _  _ _ _ _   _ _  _ _ _ _'"
            v-bind:class="{'ui-no-valid': account.validation.iban}"
            :error="account.validation.iban"
            :errorTxt="$t(`${account.validationTranslate.iban}.localization_value.value`)"
            v-model="account.data.iban"
        />
      </div>
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['account_currency'])"></div>
      {{ $t('account_currency.localization_value.value') }}
<!--      Currency-->
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_USD'])"></div>
        <LargeRadio
            :label="$t('account_USD.localization_value.value')"
            :checked="account.data.currencyId === account.data.currency.usd"
            :name="'orderCreateSwitch'"
            @change="changeSwitch"
            :value="account.data.currency.usd"
        />
      </div>
      <div class="order-create__col custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_UAN'])"></div>
        <LargeRadio
            :label="$t('account_UAN.localization_value.value')"
            :checked="account.data.currencyId === account.data.currency.uah"
            :name="'orderCreateSwitch'"
            @change="changeSwitch"
            :value="account.data.currency.uah"
        />

      </div>
    </div>


  </div>
</template>

<script>
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
import LargeRadio from "../../../../../UI/radiobuttons/LargeRadio/LargeRadio";

export default {
  name: "AccountBusiness",

  props: {
    account: Object,
  },

  components: {
    DefaultInput,
    LargeRadio,
  },


  methods: {
    changeSwitch(val) {
      this.account.setCurrencyId(val)
    }
  },

  mounted() {
    this.account.setCurrencyId(this.account.data.currency.usd)

    this.account.setFieldsValid({
      fullName: true,
      usreou: true,
      iban: true,
      beneficiaryBankName: true,
      // currencyId: true,
    })
  }
}
</script>

<style scoped>

</style>
