<template>
  <div class="order-create__section">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['account_emailAccount'])"></div>
      {{ $t('account_emailAccount.localization_value.value') }}
<!--      Email to whom we send-->
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_email'])"></div>
        <DefaultInput
            :label="$t('common_email.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.email}"
            :error="account.validation.email"
            :errorTxt="$t(`${account.validationTranslate.email}.localization_value.value`)"
            v-model="account.data.email"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "AnotherUserAccount",

  components: {
    DefaultInput,
  },

  props: {
    account: Object,
  },

  data() {
    return {

    }
  },

  mounted() {
    this.account.setFieldsValid({
      email: true,
    })
  },

}
</script>

<style scoped>

</style>
