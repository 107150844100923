<template>
  <div class="order-create__section">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['account_personalInformation'])"></div>
      {{ $t('account_personalInformation.localization_value.value') }}
    </div>

<!--    {{account.data}}-->
    <div class="order-create__row custom-row">
<!--      <div class="order-create__col custom-col custom-col&#45;&#45;66 custom-col&#45;&#45;sm-100"-->
<!--           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['account_fullName'])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('account_fullName.localization_value.value')"-->
<!--            v-bind:class="{'ui-no-valid': account.validation.name}"-->
<!--            :error="account.validation.name"-->
<!--            :errorTxt="$t(`${account.validationTranslate.name}.localization_value.value`)"-->
<!--            v-model="account.data.fullName"-->
<!--        />-->
<!--      </div>-->
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_firstName'])"></div>
        <DefaultInput
            :label="$t('account_firstName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.firstName}"
            :error="account.validation.firstName"
            :errorTxt="$t(`${account.validationTranslate.firstName}.localization_value.value`)"
            v-model="account.data.firstName"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_kastName'])"></div>
        <DefaultInput
            :label="$t('account_lastName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.lastName}"
            :error="account.validation.lastName"
            :errorTxt="$t(`${account.validationTranslate.lastName}.localization_value.value`)"
            v-model="account.data.lastName"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100 mb-5"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_phone'])"></div>
        <DefaultInput
            :label="$t('common_phone.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.phone}"
            v-mask="'+38 (###) ### ## ##'"
            :placeholder="'+38 (_ _ _)   _ _ _   _ _    _ _'"
            :error="account.validation.phone"
            :errorTxt="$t(`${account.validationTranslate.phone}.localization_value.value`)"
            v-model="account.data.phone"
        />
      </div>
    </div>

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['account_accountDetails'])"></div>
      {{ $t('account_accountDetails.localization_value.value') }}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_type'])"></div>
        <DefaultSelect
            :options="banks"
            :label="$t('account_type.localization_value.value')"
            :otherValue="'selectIcons'"
            :optionIcoName="'name'"
            :optionIcoVariable="'logo_base64'"
            :optionsLabel="'name'"
            :heightIco="16"
            v-bind:class="{'ui-no-valid': account.validation.typeSelected}"
            @change="selectType"
            :errorTxt="$t(`${account.validationTranslate.typeSelected}.localization_value.value`)"
            :error="account.validation.typeSelected"
            :selected="account.data.typeSelected"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--md-25 custom-col--sm-100 mb-5"
           v-if="account.data.typeSelected && (account.data.typeSelected.id === ACCOUNT_BANK_PRIVAT.id || account.data.typeSelected.id === ACCOUNT_BANK_MONOBANK.id)"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_iBan'])"></div>
        <DefaultInput
            :label="$t('account_iBan.localization_value.value')"
            :placeholder="'_ _  _ _  _ _ _ _ _ _  _ _ _ _ _  _ _ _ _  _ _ _ _   _ _  _ _ _ _'"
            v-bind:class="{'ui-no-valid': account.validation.iban}"
            :error="account.validation.iban"
            :errorTxt="$t(`${account.validationTranslate.iban}.localization_value.value`)"
            v-mask="'AA###########################'"
            v-model="account.data.iban"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--md-25 custom-col--sm-100 mb-5"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_cardNumber'])"></div>
        <DefaultInput
            :label="$t('account_cardNumber.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.cardNumber}"
            v-mask="'#### #### #### ####'"
            :placeholder="'_ _ _ _   _ _ _ _   _ _ _ _   _ _ _ _'"
            :error="account.validation.cardNumber"
            :errorTxt="$t(`${account.validationTranslate.cardNumber}.localization_value.value`)"
            v-model="account.data.cardNumber"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import {
  ACCOUNT_BANK_TYPE,
  ACCOUNT_BANK_PRIVAT,
  ACCOUNT_BANK_MONOBANK
} from "../../../../../../staticData/staticVariables";
import {queryFilterMixin} from "@/mixins/routeFilterMixins/queryFilterMixin";

export default {
  name: "AccountUkrainianBank",
  components: {
    DefaultInput,
    DefaultSelect,
  },

  mixins: [queryFilterMixin],

  props: {
    account: Object,
  },

  data() {
    return {
      optionsBank: [],
      banks: [],
      bankSelected: '',
      ACCOUNT_BANK_PRIVAT: ACCOUNT_BANK_PRIVAT,
      ACCOUNT_BANK_MONOBANK: ACCOUNT_BANK_MONOBANK,
    }
  },

  mounted() {
    this.getAccountBanksUkrainian()


    this.setFieldsToValidate()

    // this.account.setFieldsValid({
    //   firstName: true,
    //   lastName: true,
    //   // phone: true,
    //   typeSelected: true,
    //   cardNumber: true,
    // })
  },


  methods: {
    selectType(val){
      console.log(val);
      this.account.setTypeSelected(val)
      this.setFieldsToValidate()
    },


    setFieldsToValidate() {
      if(this.account.data.typeSelected.id === ACCOUNT_BANK_PRIVAT.id) {
        this.account.setFieldsValid({
          firstName: true,
          lastName: true,
          // phone: true,
          typeSelected: true,
          // iban: true,
          // ibanUa: true,
          cardNumber: true,
        })
      } else if(this.account.data.typeSelected.id === ACCOUNT_BANK_MONOBANK.id) {
        this.account.setFieldsValid({
          firstName: true,
          lastName: true,
          // phone: true,
          typeSelected: true,
          cardNumber: true,
        })
      } else {
        this.account.setFieldsValid({
          firstName: true,
          lastName: true,
          // phone: true,
          typeSelected: true,
          cardNumber: true,
        })
      }
    },

    getAccountBanksUkrainian(){
      let myQuery = this.createQueryBuilderObject()

      myQuery.where('type_id', ACCOUNT_BANK_TYPE.UKRAINIAN_BANK.id )

      /**
       * Withdraw banks only with the option enabled
       */
      myQuery.where('enable', true)

      let url = this.generateGetParamsFromQuery(myQuery, 10000, 0)

      this.$store.dispatch('fetchAccountBanks', url).then(() => {
        this.banks = this.$store.getters.getAccountBanks.sort((x,y) => {
          return x.id === ACCOUNT_BANK_PRIVAT.id ? -1 : y.id === ACCOUNT_BANK_PRIVAT.id ? 1 : 0
        })
      })
    }
  },
}
</script>

<style scoped>

</style>
