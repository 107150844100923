<template>
  <AccountsEditUser
    :account="account"
    @save="save({edit: true})"
  />
</template>

<script>
  import AccountsEditUser from "./AccountsEditUser/AccountsEditUser";
  import {Account} from "../../models/Account";
  import {accountMixin} from "../../../../../mixins/account/accountMixin";
  import {checkAccess} from "@/mixins/userCredentionalMixins/userCredentional";

  export default {
    name: "AccountsEdit",
    components: {
      AccountsEditUser,
    },

    mixins: [accountMixin, checkAccess],

    watch: {
      loadUserRoleAndAuthorizedData() {
        this.getUserData()
      },

      downloadPermissions() {
        this.checkPermission()

        this.accessToPersonalAccount()
      },
    },

    data(){
      return{
        account: new Account(),
      }
    },

    mounted() {
      if(this.downloadPermissions){
        if(!this.checkPermission()) return
      }

      this.accessToPersonalAccount()

      if (this.loadUserRoleAndAuthorizedData) {
        this.getUserData()
      }
    },

    methods:{
      getUserData() {
        if(!this.isAdmin) {
          this.$router.push(this.$store.getters.GET_PATHS.notFound)
          return
        }

        let accountId =  this.$route.params.id
        this.$store.dispatch('getAccount', accountId ).then((response) => {
          let item = this.getRespData(response)
          this.account.setItem(item)
        }).catch(error => this.createErrorLog(error))
      },

    },
  }
</script>

<style scoped>

</style>
