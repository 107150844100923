import { render, staticRenderFns } from "./AccountUkrainianBank.vue?vue&type=template&id=2eeee268&scoped=true"
import script from "./AccountUkrainianBank.vue?vue&type=script&lang=js"
export * from "./AccountUkrainianBank.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2eeee268",
  null
  
)

export default component.exports