<template>
  <div class="toggle-block__details-body">
    <div class="custom-row">
      <div class="custom-col custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_fullName'])"></div>
        <DefaultInput
            :label="$t('account_fullName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.fullName}"
            :error="account.validation.fullName"
            :errorTxt="$t(`${account.validationTranslate.fullName}.localization_value.value`)"
            v-model="account.data.fullName"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_phone'])"></div>
        <DefaultInput
            :label="$t('common_phone.localization_value.value')"
            v-mask="'+38 (###) ### ## ##'"
            :placeholder="'+38 (_ _ _)   _ _ _   _ _    _ _'"
            v-bind:class="{'ui-no-valid': account.validation.phone}"
            :error="account.validation.phone"
            :errorTxt="$t(`${account.validationTranslate.phone}.localization_value.value`)"
            v-model="account.data.phone"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_city'])"></div>
        <DefaultInput
            :label="$t('account_city.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.city}"
            :error="account.validation.city"
            :errorTxt="$t(`${account.validationTranslate.city}.localization_value.value`)"
            v-model="account.data.city"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "KitGroup",

  components: {
    DefaultInput,
  },

  props: {
    account: Object,
  },

  mounted() {
    this.account.setFieldsValid({
      fullName: true,
      phone: true,
      city: true,
    })
  },
}
</script>

<style scoped>

</style>
