<template>
  <div class="toggle-block__details-body">
    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_firstName'])"></div>
        <DefaultInput
            :label="$t('account_firstName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.firstName}"
            :error="account.validation.firstName"
            :errorTxt="$t(`${account.validationTranslate.firstName}.localization_value.value`)"
            v-model="account.data.firstName"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_lastName'])"></div>
        <DefaultInput
            :label="$t('account_lastName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.lastName}"
            :error="account.validation.lastName"
            :errorTxt="$t(`${account.validationTranslate.lastName}.localization_value.value`)"
            v-model="account.data.lastName"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_email'])"></div>
        <DefaultInput
            :label="$t('common_email.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.email}"
            :error="account.validation.email"
            :errorTxt="$t(`${account.validationTranslate.email}.localization_value.value`)"
            v-model="account.data.email"
        />
      </div>

    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "PayPal",

  components: {
    DefaultInput,
  },

  props: {
    account: Object,
  },

  mounted() {
    this.account.setFieldsValid({
      firstName: true,
      lastName: true,
      email: true,
    })
  },
}
</script>

<style scoped>

</style>