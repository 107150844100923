<template>
  <modal
          @close="$emit('close')"
          class="choose-account-type"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_chooseAccountType'])"></div>
        {{$t('account_chooseAccountType.localization_value.value')}}
      </div>
<!--      Choose Account Type-->
    </template>
    <template slot="body">

      <div class="what-order-create__list custom-row">
        <div class="what-order-create__item custom-col custom-col--50 custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_chooseAccountTypeBusiness'])"></div>
          <router-link
                  :to="$store.getters.GET_PATHS.accountsCreate + ACCOUNT_BANK_TYPE.CUSTOM_BANK.name"
                  class="what-order-create__link"
          >
            <div class="what-order-create__ico">
              <BagAccountsTypeIco/>
            </div>
            <div>
              {{$t('account_chooseAccountTypeBusiness.localization_value.value')}}
<!--              Business-->
            </div>
          </router-link>
        </div>
        <div class="what-order-create__item custom-col custom-col--50 custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_chooseAccountTypeUkrainian'])"></div>
          <router-link
                  :to="$store.getters.GET_PATHS.accountsCreate + ACCOUNT_BANK_TYPE.UKRAINIAN_BANK.name"
                  class="what-order-create__link"
          >
            <div class="what-order-create__ico">
              <ManAccountsTypeIco/>
            </div>
            <div v-html="$t('account_chooseAccountTypeUkrainian.localization_value.value')"></div>
<!--            <div>-->
<!--              Personal-->
<!--              <i class="flag-ua"></i> <br>-->
<!--              Ukrainian-->
<!--              Bank-->
<!--            </div>-->
          </router-link>
        </div>
      </div>
      <div class="what-order-create__list custom-row mt-3">
        <div class="what-order-create__item custom-col custom-col--50 custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_chooseAccountTypeWorld'])"></div>
          <router-link
                  :to="$store.getters.GET_PATHS.accountsCreate + ACCOUNT_BANK_TYPE.WORLD_BANK.name"
                  class="what-order-create__link"
          >
            <div class="what-order-create__ico choose-account-type__ico">
              <EarthAccountsTypeIco/>
            </div>
            <div>
              {{$t('account_chooseAccountTypeWorld.localization_value.value')}}
<!--              World Bank & Platform-->
            </div>
          </router-link>
        </div>
        <div class="what-order-create__item custom-col custom-col--50 custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['account_chooseAccountTypeUser'])"></div>
          <router-link
                  :to="$store.getters.GET_PATHS.accountsCreate + ACCOUNT_BANK_TYPE.CUSTOM_ACCOUNT.name"
                  class="what-order-create__link"
          >
            <div class="what-order-create__ico">
              <DogAccountsTypeIco/>
            </div>
            <div>
              {{$t('account_chooseAccountTypeUser.localization_value.value')}}
<!--              To Another User-->
            </div>
          </router-link>
        </div>
      </div>

    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import ManAccountsTypeIco from '../../../../../../public/img/modal-group/account-man.svg?inline'
  import EarthAccountsTypeIco from '../../../../../../public/img/modal-group/account-earth.svg?inline'
  import DogAccountsTypeIco from '../../../../../../public/img/modal-group/account-dog.svg?inline'
  import BagAccountsTypeIco from '../../../../../../public/img/modal-group/account-bag.svg?inline'
  import {ACCOUNT_BANK_TYPE} from "../../../../../staticData/staticVariables";

  export default {
    name: "ChooseAccountTypePopup",
    components: {
      Modal,
      BagAccountsTypeIco,
      ManAccountsTypeIco,
      EarthAccountsTypeIco,
      DogAccountsTypeIco,
    },

    data() {
      return {
        ACCOUNT_BANK_TYPE: ACCOUNT_BANK_TYPE,
      }
    }
  }

</script>

<style lang="scss">

  @import "../../../../../scss/mixins/mixins";
  @import "../../../../../scss/colors";

  .choose-account-type{

    .modal-component__inner {
      max-width: 608px;
    }

    &__ico + span{
      position: relative;
      z-index: 2;
    }

    .what-order-create__link:hover .what-order-create__ico {
      opacity: 1;
      filter: brightness(1) invert(0) grayscale(0);
    }

    .what-order-create__link:hover {
      background-color: #CD9E64;
    }

    .what-order-create__link:after{
      display: none;
    }

    .flag-ua{
      display: inline-block;
      width: 20px;
      height: 20px;
      position: relative;
      top: -5px;
      background: url("../../../../../assets/img/common/flag-ua.png");
    }
  }

</style>
