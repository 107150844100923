<template>
  <div class="toggle-block__details-body">
    <div class="custom-row">
<!--      <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sm-100 "-->
<!--           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['account_firstName'])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('account_firstName.localization_value.value')"-->
<!--            v-bind:class="{'ui-no-valid': account.validation.firstName}"-->
<!--            :error="account.validation.firstName"-->
<!--            :errorTxt="$t(`${account.validationTranslate.firstName}.localization_value.value`)"-->
<!--            v-model="account.data.firstName"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sm-100 "-->
<!--           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['account_lastName'])"></div>-->
<!--        <DefaultInput-->
<!--            :label="$t('account_lastName.localization_value.value')"-->
<!--            v-bind:class="{'ui-no-valid': account.validation.lastName}"-->
<!--            :error="account.validation.lastName"-->
<!--            :errorTxt="$t(`${account.validationTranslate.lastName}.localization_value.value`)"-->
<!--            v-model="account.data.lastName"-->
<!--        />-->
<!--      </div>-->
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_displayName'])"></div>
        <DefaultInput
            :label="$t('account_displayName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.middleName}"
            :error="account.validation.middleName"
            :errorTxt="$t(`${account.validationTranslate.middleName}.localization_value.value`)"
            v-model="account.data.middleName"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_cardNumber'])"></div>
        <DefaultInput
            :label="$t('account_cardNumber.localization_value.value')"
            v-mask="'#### #### #### ####'"
            :placeholder="'_ _ _ _   _ _ _ _   _ _ _ _   _ _ _ _'"
            v-bind:class="{'ui-no-valid': account.validation.cardNumber}"
            :error="account.validation.cardNumber"
            :errorTxt="$t(`${account.validationTranslate.cardNumber}.localization_value.value`)"
            v-model="account.data.cardNumber"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "Bento",

  components: {
    DefaultInput,
  },

  props: {
    account: Object,
  },

  mounted() {
    this.account.setFieldsValid({
      // firstName: true,
      // lastName: true,
      middleName: true,
      cardNumber: true,
    })
  },
}
</script>

<style scoped>

</style>