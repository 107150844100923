<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['account_newAccount'])"></div>
      <CardLeftBlock
              :backgroundImage="'accounts'"
              class="cus-payments"
      >
        <template slot="body">
          <h2 class="mt-0">
            {{$t('account_newAccount.localization_value.value')}}
          </h2>

          <div class="card-detail-left__date mt-1">
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>

        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['account_backToAccounts'])"></div>
                <router-link :to="$store.getters.GET_PATHS.accounts" class="detail-page__head-back">
                  <LinkBack
                      :value="$t('account_backToAccounts.localization_value.value')"
                  />
<!--                      :value="'Back to All Listings' "-->
                </router-link>
              </div>
            </div>

            <template v-if="$route.params.type === ACCOUNT_BANK_TYPE.CUSTOM_BANK.name">
              <div class="order-create__head-title"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['account_newAccBusiness'])"></div>
                {{$t('account_newAccBusiness.localization_value.value')}}
<!--                New Account Business-->
              </div>
              <div class="order-create__head-txt mb-0"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['account_pleaseTxt'])"></div>
                {{$t('account_pleaseTxt.localization_value.value')}}
<!--                Please, fill out all the information below to create new account-->
              </div>
            </template>

            <template v-if="$route.params.type === ACCOUNT_BANK_TYPE.UKRAINIAN_BANK.name">
              <div class="order-create__head-title"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['account_newAccUkrBank'])"></div>
                {{$t('account_newAccUkrBank.localization_value.value')}}
<!--                New Account Ukrainian Bank-->
              </div>
              <div class="order-create__head-txt mb-0"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['account_pleaseTxt'])"></div>
                {{$t('account_pleaseTxt.localization_value.value')}}
              </div>
            </template>
            <template v-if="$route.params.type === ACCOUNT_BANK_TYPE.WORLD_BANK.name">
              <div class="order-create__head-title"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['account_newAccPersonalWorld'])"></div>
                {{$t('account_newAccPersonalWorld.localization_value.value')}}
<!--                New Personal Account (World)-->
              </div>
              <div class="order-create__head-txt mb-0"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['account_pleaseTxt'])"></div>
                {{$t('account_pleaseTxt.localization_value.value')}}
              </div>
            </template>
            <template v-if="$route.params.type === ACCOUNT_BANK_TYPE.CUSTOM_ACCOUNT.name">
              <div class="order-create__head-title"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['account_newAccUserEmail'])"></div>
                {{$t('account_newAccUserEmail.localization_value.value')}}
<!--                Another User Account (Email)-->
              </div>
              <div class="order-create__head-txt mb-0"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['account_pleaseTxt'])"></div>
                {{$t('account_pleaseTxt.localization_value.value')}}
              </div>
            </template>


          </div>
        </template>

        <template slot="body">
          <div class="order-create__section" v-if="$store.getters.getIsAdminRights === 'admin'">
            <div class="custom-row" >
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                    :validationUser="account.validation.user"
                    :validationTxtUser="account.validationTxt.user"
                    :validationTranslateUser="account.validationTranslate.user"
                    :userSelected="account.data.user"
                    @changeUser="changeUser"
                    :typeSelect="'users'"
                    :userEmail="true"
                />
              </div>
            </div>
          </div>

          <AccountBusiness
                  :account="account"
                  v-if="$route.params.type === ACCOUNT_BANK_TYPE.CUSTOM_BANK.name"
          />
          <AccountUkrainianBank
                  :account="account"
                  v-if="$route.params.type === ACCOUNT_BANK_TYPE.UKRAINIAN_BANK.name"
          />

          <AccountWorldBank
                  :account="account"
                  v-if="$route.params.type === ACCOUNT_BANK_TYPE.WORLD_BANK.name"
          />

          <AnotherUserAccount
                  :account="account"
                  v-if="$route.params.type === ACCOUNT_BANK_TYPE.CUSTOM_ACCOUNT.name"
          />
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
              <router-link :to="$store.getters.GET_PATHS.accounts"
                           class="site-link site-link--alt"
              >
                {{ $t('common_cancel.localization_value.value') }}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_create'])"></div>
              <MainButton
                  class="order-create__footer-btn-i"
                  :value="$t('common_create.localization_value.value')"
                  @click.native="$emit('save')"
                  v-bind:class="{'disabled-btn' : $store.getters.getAccountBtn}"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import {ACCOUNT_BANK_TYPE} from "../../../../../../staticData/staticVariables";
  import {radioButtonsFunc} from "../../../../../../mixins/creationPageMixins/radioButtonsFunc";
  import AccountUkrainianBank from "../../components/AccountUkrainianBank/AccountUkrainianBank";
  import AccountBusiness from "../../components/AccountBusiness/AccountBusiness";
  import AccountWorldBank from "../../components/AccountWorldBank/AccountWorldBank";
  import AnotherUserAccount from "../../components/AnotherUserAccount/AnotherUserAccount";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import {accountMixin} from "../../../../../../mixins/account/accountMixin";


  export default {
    name: "AccountsCreateUser",

    components: {
      CardLeftBlock,
      AccountUkrainianBank,
      AccountBusiness,
      AccountWorldBank,
      AnotherUserAccount,
      CardRightBlock,
      LinkBack,
      MainButton,
      UserSelect,
    },

    props: {
      account: Object,
    },

    mixins: [radioButtonsFunc, accountMixin],

    mounted() {
      console.log(this.$route.params.type);
    },

    data() {
      return {
        ACCOUNT_BANK_TYPE: ACCOUNT_BANK_TYPE,

        name: '',

        email: 'ivanov_ivan@gmail.com',

        // optionsUkrainianBank: [
        //   {
        //     title: 'Privat Bank',
        //     ico: '/img/trash-group/privat.png',
        //   },
        //   {
        //     title: 'OTP Bank',
        //     ico: '/img/trash-group/otp.png',
        //   },
        //   {
        //     title: 'Raiffeisen Bank Oval',
        //     ico: '/img/trash-group/oval.png',
        //   },
        //   {
        //     title: 'Alfa Bank',
        //     ico: '/img/trash-group/alfa.png',
        //   },
        //   {
        //     title: 'Monobank',
        //     ico: '/img/trash-group/mono.png',
        //   },
        // ],
        //
        // optionsBusiness: [
        //   {
        //     title: 'USREOU / INP',
        //   },
        //   {
        //     title: 'USREOU',
        //   },
        //   {
        //     title: 'INP',
        //   },
        // ],
        //
        // mfo: '',
        // card: '',
        //
        // systemType: {
        //   swift: {
        //     name: 'Swift',
        //     type: '',
        //     icon: '/img/company-icons-group/swift.png',
        //     value: true,
        //   },
        //
        //   payoneer: {
        //     name: 'Payoneer',
        //     type: '',
        //     icon: '/img/company-icons-group/payoneer.png',
        //     value: false,
        //   },
        //
        //   ePayments: {
        //     name: 'E-payments',
        //     type: '',
        //     icon: '/img/company-icons-group/e-payments.png',
        //     value: false,
        //   },
        //
        //   payPal: {
        //     name: 'PayPal',
        //     type: '',
        //     icon: '/img/company-icons-group/paypal.png',
        //     value: false,
        //   },
        //
        //   kITGroup: {
        //     name: 'KIT Group',
        //     type: '',
        //     icon: '/img/company-icons-group/kit-group.png',
        //     value: false,
        //   },
        //
        //   bentoVisaCard: {
        //     name: 'Bento Visa Card',
        //     type: '',
        //     icon: '/img/company-icons-group/bento.png',
        //     value: false,
        //   },
        // }

      }
    },

    methods: {
      inputTextEditor(val) {
        this.text = val
      },

      changeImg(files) {
        this.files = files
      },

    }
  }
</script>

<style scoped>

</style>

