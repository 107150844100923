
<template>
  <AccountsCreateUser
    :account="account"
    @save="save"
  />
</template>

<script>
  import AccountsCreateUser from "./AccountsCreateUser/AccountsCreateUser";
  import {Account} from "../../models/Account";
  import {accountMixin} from "../../../../../mixins/account/accountMixin";
  import {checkAccess} from "@/mixins/userCredentionalMixins/userCredentional";

  export default {
    name: "AccountsCreate",
    components: {
      AccountsCreateUser,
    },

    mixins: [accountMixin, checkAccess],

    watch: {
      loadUserRoleAndAuthorizedData: function (val) {
        if(val){
          this.getUserData()
        }
      },

      downloadPermissions() {
        this.checkPermission()

        this.accessToPersonalAccount()
      },
    },

    data(){
      return{
        account: new Account(),
      }
    },

    mounted() {
      if(this.downloadPermissions){
        if(!this.checkPermission()) return

        this.accessToPersonalAccount()
      }

      if (this.loadUserRoleAndAuthorizedData) {
        this.getUserData()
      }
    },

    methods:{
      getUserData() {
        if(this.isCurrentUserRoleUser){
          let userData = this.$store.getters.GET_COMMON_AUTHORIZED.user
          this.account.setAccountUser(userData)
          // console.log(11111, this.account.data);
        }
      },
    },
  }
</script>

<style scoped>

</style>
