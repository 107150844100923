<template>
  <div class="fragment">
    <template v-if="$store.getters.getAccount.length > 0 && $store.getters.getAccountLoading !== true">
      <div class="custom-row accounts-table">
        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getAccount"
             :key="index">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">

                <div class="case-cart__content-block">
                  <router-link :to="openAccountForEdit(item)"
                               v-bind:class="{'disabled-link': !isAdmin}"
                       class="d-flex align-items-center pt-4"
                  v-if="item['bank'] !== null">
                    <div class="case-cart__img-block accounts-table__case-cart-img-block pt-0">
                      <div class="case-cart__img accounts-table__case-cart-img"
                           v-if="item['bank']['svg_logo'] !== null"
                      >
                        <div v-html="item['bank']['svg_logo']"></div>
                      </div>
                      <div class="case-cart__img accounts-table__case-cart-img"
                           v-else>
                        <img src="/img/dashboard-group/balance-icon.svg" alt="img">
                      </div>
                    </div>
                    <b class="accounts-table__bold btn-style"
                       v-if="item['bank']['name'] !== null"
                    >
                      {{item["bank"]["name"]}}
                    </b>
                  </router-link>
                  <div v-else class="d-flex align-items-center pt-4"
                       v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['account_bankNotFound'])"></div>
                    <b class="accounts-table__bold case-cart__body pb-0">
<!--                      Bank not found or was deleted-->
                      {{ $t('account_bankNotFound.localization_value.value') }}
                    </b>
                  </div>
                  <div class="case-cart__body pb-0 pt-0"  v-if="$store.getters.getIsAdminRights === 'admin'">
                    <div class="case-cart__row custom-row" >
                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['common_user'])"></div>
                          {{ $t('common_user.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <b class="accounts-table__bold">
<!--                            {{item["user"]["user_personal_contact"]["user_full_name"]}}-->
                            <ValueHelper
                                :value="item"
                                :deep="'user.user_personal_contact.user_full_name'"
                            />
                          </b>
                          <ValueHelper
                              :value="item"
                              :deep="'user.user_personal_contact.email'"
                          />
<!--                          {{item["user"]["user_personal_contact"]["email"]}}-->
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="case-cart__head pt-2 pb-1" v-if="item['usreou']">
                    <div class="case-cart__title-caption"
                         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['account_usreou'])"></div>
                      {{ $t('account_usreou.localization_value.value') }}
                    </div>
                    <div class="case-cart__title case-cart__title--status">
                      <span>
                        {{item["usreou"]}}
                      </span>
                    </div>
                  </div>

                  <div class="case-cart__head pt-2 pb-1" v-if="item['card_number']">
                    <div class="case-cart__title-caption"
                         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['account_cardNumber'])"></div>
                      {{ $t('account_cardNumber.localization_value.value') }}
                    </div>
                    <div class="case-cart__title case-cart__title--status">
                      <span>
                        {{item["card_number"]}}
                      </span>
                    </div>
                  </div>

                  <div class="case-cart__head pt-2 pb-1" v-if="item['iban'] && item.bank.id === ACCOUNT_BANK_PRIVAT.id || item.bank.id === ACCOUNT_BANK_MONOBANK.id">
                    <div class="case-cart__title-caption"
                         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['account_iBan'])"></div>
                      {{ $t('account_iBan.localization_value.value') }}
                    </div>
                    <div class="case-cart__content">
                      <b style="overflow-wrap: break-word;">
                        {{item["iban"]}}
                      </b>
                    </div>
                  </div>

                  <div class="case-cart__head pt-2 pb-1" v-if="item['crypto_wallet']">
                    <div class="case-cart__title-caption"
                         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['account_cryptoWallet'])"></div>
                      {{ $t('account_cryptoWallet.localization_value.value') }}
                    </div>
                    <div class="case-cart__title case-cart__title--status">
                      <span>
                        {{item["crypto_wallet"]}}
                      </span>
                    </div>
                  </div>
                  <div class="case-cart__body">

                    <div class="case-cart__row custom-row"
                         v-if="item['full_name']">

                      <div class="case-cart__col custom-col"
                           v-bind:class="{'custom-col--50': ACCOUNT_BANK_PAYONEER.id === item['bank_id']}"
                      >
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['account_fullName'])"></div>
                          {{ $t('account_fullName.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <b class="accounts-table__bold">{{item['full_name']}}</b>
                        </div>
                      </div>

                      <div class="case-cart__col custom-col custom-col--50"
                           v-if="ACCOUNT_BANK_PAYONEER.id === item['bank_id']"
                           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate(['account_permission', 'account_getAPIPermission', 'account_payoneerConfirmed'])"></div>
                        <div class="case-cart__label">
                          {{$t('account_permission.localization_value.value')}}
                        </div>
                        <div class="case-cart__content status--active"
                             v-if="item['verify'] === '0'"
                             @click="getAPIPermission(item)"
                        >
                          {{$t('account_getAPIPermission.localization_value.value')}}
                        </div>
                        <div class="case-cart__content status--active cursor-initial"
                             v-else
                        >
                             <!--@click="removePermission(item.id)"-->
                          {{$t('account_payoneerConfirmed.localization_value.value')}}
                        </div>
                      </div>

                    </div>

                    <div class="case-cart__row custom-row"
                         v-if="item['for_usa'] === 0 && ACCOUNT_BANK_WISE.id === item['bank_id']"
                    >
                      <div class="case-cart__col custom-col custom-col--100">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['account_iBan'])"></div>
                          {{ $t('account_iBan.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <b class="accounts-table__bold">
                            {{item['iban']}}
                          </b>
                        </div>
                      </div>
                      <div v-if="item['country_id']" class="case-cart__col custom-col custom-col--100">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
                          {{ $t('common_country.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <b class="accounts-table__bold">
                            {{ item['country']['currentTranslate']['name']}}
                          </b>
                        </div>
                      </div>
                    </div>

                    <div class="case-cart__row custom-row"
                         v-if="item['first_name'] || item['last_name'] || (item['middle_name'] && ACCOUNT_BANK_RAMP.id === item['bank_id'])"
                    >

                      <template v-if="item['middle_name'] && ACCOUNT_BANK_RAMP.id === item['bank_id']">
                        <div class="case-cart__col custom-col custom-col--50">
                          <div class="case-cart__label"
                               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                            <div class="admin-edit" @click="editTranslate(['account_displayName'])"></div>
                            {{ $t('account_displayName.localization_value.value') }}
                          </div>
                          <div class="case-cart__content">
                            <b class="accounts-table__bold">
                              {{item['middle_name']}}
                            </b>
                          </div>
                        </div>
                      </template>

                      <template v-else>
                        <div class="case-cart__col custom-col custom-col--50">
                          <div class="case-cart__label"
                               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                            <div class="admin-edit" @click="editTranslate(['account_firstName'])"></div>
                            {{ $t('account_firstName.localization_value.value') }}
                          </div>
                          <div class="case-cart__content">
                            <b class="accounts-table__bold">
                              {{item['first_name']}}
                            </b>
                          </div>
                        </div>
                        <div class="case-cart__col custom-col custom-col--50">
                          <div class="case-cart__label"
                               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                            <div class="admin-edit" @click="editTranslate(['account_lastName'])"></div>
                            {{ $t('account_lastName.localization_value.value') }}
                          </div>
                          <div class="case-cart__content">
                            <b class="accounts-table__bold">
                              {{ item['last_name']}}
                            </b>
                          </div>
                        </div>
                      </template>
                    </div>

                    <div class="case-cart__row custom-row"
                         v-if="item['phone']"
                    >
                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['common_phone'])"></div>
                          {{ $t('common_phone.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <b class="accounts-table__bold">
                            {{item['phone']}}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div class="case-cart__row custom-row"
                         v-if="item['email']"
                    >
                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['common_email'])"></div>
                          {{ $t('common_email.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <b class="accounts-table__bold">
                            {{item['email']}}
                          </b>
                        </div>
                      </div>
                    </div>

                    <div class="case-cart__row custom-row"
                         v-if="item['account_number']"
                    >
                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['account_accountNumber'])"></div>
                          {{ $t('account_accountNumber.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <b class="accounts-table__bold">
                            {{item['account_number']}}
                          </b>
                        </div>
                      </div>
                    </div>

                    <div class="case-cart__row custom-row"
                         v-if="item['correspondent_bank_name']"
                    >
                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['account_correspondentBankName'])"></div>
                          {{ $t('account_correspondentBankName.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <b class="accounts-table__bold">
                            {{item['correspondent_bank_name']}}
                          </b>
                        </div>
                      </div>
                    </div>
                    <div class="case-cart__row custom-row"
                         v-if="item['beneficiary_bank_name']"
                    >
                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['account_beneficiaryBankName'])"></div>
                          {{ $t('account_beneficiaryBankName.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <b class="accounts-table__bold">
                            {{item['beneficiary_bank_name']}}
                          </b>
                        </div>
                      </div>
                    </div>

                    <div class="case-cart__row custom-row"
                         v-if="item['crypto_name']"
                    >
                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['account_cryptoName'])"></div>
                          {{ $t('account_cryptoName.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <b class="accounts-table__bold">
                            {{item['crypto_name']}}
                          </b>
                        </div>
                      </div>
                    </div>

                    <div class="case-cart__row custom-row"
                         v-if="item['crypto_tag']"
                    >
                      <div class="case-cart__col custom-col">
                        <div class="case-cart__label"
                             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['account_cryptoTag'])"></div>
                          {{ $t('account_cryptoTag.localization_value.value') }}
                        </div>
                        <div class="case-cart__content">
                          <b class="accounts-table__bold">
                            {{item['crypto_tag']}}
                          </b>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="case-cart__bottom"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_delete', 'account_sendToArchive', 'account_restoreFromArchive'])"></div>
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="$t('common_delete.localization_value.value')"
                      :ico="true"
                      :typeIco="'delete-brown'"
                      @click.native="removeItem(item.id, index)"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      v-if="item['in_archive'] === ACCOUNT_TYPE_STATUS_ARCHIVE.INACTIVE"
                      :value="$t('account_sendToArchive.localization_value.value')"
                      :ico="true"
                      :typeIco="'archive'"
                      @click.native="sendToArchive(item.id)"
                  />
<!--                      :value="'Send to Archive'"-->
                  <CaseCartButton
                      v-if="item['in_archive'] === ACCOUNT_TYPE_STATUS_ARCHIVE.ACTIVE"
                      :value="$t('account_restoreFromArchive.localization_value.value')"
                      @click.native="restoreFromArchive(item.id)"
                  />
<!--                      :value="'Restore from Archive'"-->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="display: none">
          <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33">
            <div class="case-cart">
              <div class="case-cart__inner">
                <div class="case-cart__columns">

                  <div class="case-cart__content-block">
                    <div class="d-flex align-items-center pt-4">

                      <div class="case-cart__img-block accounts-table__case-cart-img-block pt-0">
                        <div class="case-cart__img accounts-table__case-cart-img">
                          <img src="/img/trash-group/privat.png" alt="img">
                        </div>
                      </div>
                      <b class="accounts-table__bold">Privat Bank</b>
                    </div>
                    <div class="case-cart__head pt-2 pb-1">
                      <div class="case-cart__title case-cart__title--status">
                    <span>
                      5645822939475726
                    </span>
                      </div>
                    </div>
                    <div class="case-cart__body">
                      <div class="case-cart__row custom-row pb-1">
                        <div class="case-cart__col custom-col">
                          <b class="accounts-table__bold">Ivanov Ivan Ivanovych</b>
                        </div>
                      </div>
                      <div class="case-cart__row custom-row">
                        <div class="case-cart__col custom-col">
                          <b class="accounts-table__bold">+38 (095) 145 93 23</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="case-cart__bottom">
                  <div class="case-cart__btn ml-auto">
                    <CaseCartButton
                        class="secondary-brown"
                        :value="'Delete'"
                        :ico="true"
                        :typeIco="'delete-brown'"
                    >
                    </CaseCartButton>
                  </div>
                  <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                    <CaseCartButton
                        :value="'Send to Archive'"
                        :ico="true"
                        :typeIco="'archive'"
                    >
                    </CaseCartButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33">
            <div class="case-cart">
              <div class="case-cart__inner">
                <div class="case-cart__columns">

                  <div class="case-cart__content-block">
                    <div class="d-flex align-items-center pt-4">

                      <div class="case-cart__img-block accounts-table__case-cart-img-block pt-0">
                        <div class="case-cart__img accounts-table__case-cart-img">
                          <img src="/img/trash-group/otp.png" alt="img">
                        </div>
                      </div>
                      <b class="accounts-table__bold">OTP Bank</b>
                    </div>
                    <div class="case-cart__head pt-2 pb-1">
                      <div class="case-cart__title case-cart__title--status">
                    <span>
                      5645822939475726
                    </span>
                      </div>
                    </div>
                    <div class="case-cart__body">
                      <div class="case-cart__row custom-row pb-1">
                        <div class="case-cart__col custom-col">
                          <b class="accounts-table__bold">Ivanov Ivan Ivanovych</b>
                        </div>
                      </div>
                      <div class="case-cart__row custom-row">
                        <div class="case-cart__col custom-col">
                          <b class="accounts-table__bold">+38 (095) 145 93 23</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="case-cart__bottom">
                  <div class="case-cart__btn ml-auto">
                    <CaseCartButton
                        class="secondary-brown"
                        :value="'Delete'"
                        :ico="true"
                        :typeIco="'delete-brown'"
                    >
                    </CaseCartButton>
                  </div>
                  <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                    <CaseCartButton
                        :value="'Send to Archive'"
                        :ico="true"
                        :typeIco="'archive'"
                    >
                    </CaseCartButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33">
            <div class="case-cart">
              <div class="case-cart__inner">
                <div class="case-cart__columns">

                  <div class="case-cart__content-block">
                    <div class="d-flex align-items-center pt-4">

                      <div class="case-cart__img-block accounts-table__case-cart-img-block pt-0">
                        <div class="case-cart__img accounts-table__case-cart-img">
                          <img src="/img/trash-group/oval.png" alt="img">
                        </div>
                      </div>
                      <b class="accounts-table__bold">Raiffeisen Bank Oval</b>
                    </div>
                    <div class="case-cart__head pt-2 pb-1">
                      <div class="case-cart__title case-cart__title--status">
                    <span>
                      5645822939475726
                    </span>
                      </div>
                    </div>
                    <div class="case-cart__body">
                      <div class="case-cart__row custom-row pb-1">
                        <div class="case-cart__col custom-col">
                          <b class="accounts-table__bold">Ivanov Ivan Ivanovych</b>
                        </div>
                      </div>
                      <div class="case-cart__row custom-row">
                        <div class="case-cart__col custom-col">
                          <b class="accounts-table__bold">+38 (095) 145 93 23</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="case-cart__bottom">
                  <div class="case-cart__btn ml-auto">
                    <CaseCartButton
                        class="secondary-brown"
                        :value="'Delete'"
                        :ico="true"
                        :typeIco="'delete-brown'"
                    >
                    </CaseCartButton>
                  </div>
                  <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                    <CaseCartButton
                        :value="'Send to Archive'"
                        :ico="true"
                        :typeIco="'archive'"
                    >
                    </CaseCartButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33">
            <div class="case-cart">
              <div class="case-cart__inner">
                <div class="case-cart__columns">

                  <div class="case-cart__content-block">
                    <div class="d-flex align-items-center pt-4">

                      <div class="case-cart__img-block accounts-table__case-cart-img-block pt-0">
                        <div class="case-cart__img accounts-table__case-cart-img">
                          <img src="/img/trash-group/alfa.png" alt="img">
                        </div>
                      </div>
                      <b class="accounts-table__bold">Alfa Bank</b>
                    </div>
                    <div class="case-cart__head pt-2 pb-1">
                      <div class="case-cart__title case-cart__title--status">
                    <span>
                      5645822939475726
                    </span>
                      </div>
                    </div>
                    <div class="case-cart__body">
                      <div class="case-cart__row custom-row pb-1">
                        <div class="case-cart__col custom-col">
                          <b class="accounts-table__bold">Ivanov Ivan Ivanovych</b>
                        </div>
                      </div>
                      <div class="case-cart__row custom-row">
                        <div class="case-cart__col custom-col">
                          <b class="accounts-table__bold">+38 (095) 145 93 23</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="case-cart__bottom">
                  <div class="case-cart__btn ml-auto">
                    <CaseCartButton
                        class="secondary-brown"
                        :value="'Delete'"
                        :ico="true"
                        :typeIco="'delete-brown'"
                    >
                    </CaseCartButton>
                  </div>
                  <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                    <CaseCartButton
                        :value="'Send to Archive'"
                        :ico="true"
                        :typeIco="'archive'"
                    >
                    </CaseCartButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33">
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__columns">

                <div class="case-cart__content-block">
                  <div class="d-flex align-items-center pt-4">

                    <div class="case-cart__img-block accounts-table__case-cart-img-block pt-0">
                      <div class="case-cart__img accounts-table__case-cart-img">
                        <img src="/img/trash-group/mono.png" alt="img">
                      </div>
                    </div>
                    <b class="accounts-table__bold">Monobank</b>
                  </div>
                  <div class="case-cart__head pt-2 pb-1">
                    <div class="case-cart__title case-cart__title--status">
                  <span>
                    5645822939475726
                  </span>
                    </div>
                  </div>
                  <div class="case-cart__body">
                    <div class="case-cart__row custom-row pb-1">
                      <div class="case-cart__col custom-col">
                        <b class="accounts-table__bold">Ivanov Ivan Ivanovych</b>
                      </div>
                    </div>
                    <div class="case-cart__row custom-row">
                      <div class="case-cart__col custom-col">
                        <b class="accounts-table__bold">+38 (095) 145 93 23</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto">
                  <CaseCartButton
                      class="secondary-brown"
                      :value="'Delete'"
                      :ico="true"
                      :typeIco="'delete-brown'"
                  >
                  </CaseCartButton>
                </div>
                <div class="case-cart__btn accounts-table__case-cart-button ml-auto">
                  <CaseCartButton
                      :value="'Send to Archive'"
                      :ico="true"
                      :typeIco="'archive'"
                  >
                  </CaseCartButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getAccountCommonList.next_page_url !== null && $store.getters.getAccount.length > 0"
              @click.native="$emit('showMore')"
              v-bind:class="{'disabled-btn' : $store.getters.getNextAccountPage}"
              :count="$store.getters.getAccountCommonList.total - $store.getters.getAccountForPage < $store.getters.getAccountForPage ?
                  $store.getters.getAccountCommonList.total - $store.getters.getAccountForPage:
                  $store.getters.getAccountForPage"
          />
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getAccountLoading === false && $store.getters.getAccount.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </template>

    <LinkEmailPopup
            v-if="isModalLinkEmailPopup"
            @close="changeLinkEmailPopup(false)"
    />
  </div>
</template>

<script>
  import CaseCartButton from "../../../../../../UI/buttons/CaseCartButton/CaseCartButton";
  import LinkEmailPopup from "../../../../popups/LinkEmailPopup/LinkEmailPopup";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import {accountMixin} from "../../../../../../../mixins/account/accountMixin";
  import {
    ACCOUNT_BANK_PAYONEER,
    ACCOUNT_BANK_WISE,
    ACCOUNT_BANK_TYPE,
    ACCOUNT_TYPE_STATUS_ARCHIVE, ACCOUNT_BANK_RAMP, ACCOUNT_BANK_PRIVAT, ACCOUNT_BANK_MONOBANK
  } from "../../../../../../../staticData/staticVariables";
  import {accountsPayoneerMixin} from "../../../../../../../mixins/account/payoneerMixin";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";


  export default {
    name: "AccountsTable",
    components: {
      ValueHelper,
      CaseCartButton,
      LinkEmailPopup,
      ShowMore,
      NoResult,
    },

    props: {
      countFilterParams: Number,
    },

    mixins: [accountMixin, accountsPayoneerMixin],

    data() {
      return {
        isModalLinkEmailPopup: false,
        ACCOUNT_TYPE_STATUS_ARCHIVE: ACCOUNT_TYPE_STATUS_ARCHIVE,
        ACCOUNT_BANK_PAYONEER: ACCOUNT_BANK_PAYONEER,
        ACCOUNT_BANK_WISE: ACCOUNT_BANK_WISE,
        ACCOUNT_BANK_RAMP: ACCOUNT_BANK_RAMP,
        ACCOUNT_BANK_PRIVAT: ACCOUNT_BANK_PRIVAT,
        ACCOUNT_BANK_MONOBANK: ACCOUNT_BANK_MONOBANK,
      }
    },

    mounted() {
      // console.log(this.$route?.query?.account_id);
      if(this.$route?.query?.account_id){
        this.confirmPayoneerAPI(this.$route?.query?.account_id)
      }
    },

    methods: {
      sendToArchive(id){
        this.$store.dispatch('sendAccountToArchive', id).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.$emit('reload')
            this.openNotify('success', 'common_notificationRecordAddedToArchive')
            this.$emit('reload')
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }

        })
      },

      restoreFromArchive(id){
        this.$store.dispatch('restoreAccountFromArchive', id).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.$emit('reload')
            this.openNotify('success', 'common_notificationRecordRestoreFromArchive')
            this.$emit('reload')
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })
      },

      openAccountForEdit(val){
        let data = {
          route: this.$store.getters.GET_PATHS.accounts,
          itemId: val.id,
          bankTypeId: val["bank"]["type_id"],
        }
        let bankType = this._.find(ACCOUNT_BANK_TYPE , ['id', data.bankTypeId]);
        return `${data.route}/${bankType.name}/${data.itemId}`
      },

      changeLinkEmailPopup(val) {
        this.isModalLinkEmailPopup = val
      },

    }
  }
</script>

<style scoped lang="scss">

  .disabled-link {
    pointer-events: none;
  }

  .accounts-table {
    padding-top: 24px;

    &__bold {
      font-size: 14px;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &__case-cart-img{
      width: auto;
      height: 45px;

      img{
        width: auto;
        height: auto;
        object-fit: initial;
      }

      > div{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg{
          height: 100%;
        }
      }
    }

    &__case-cart-img-block{
      max-width: 69px;
    }

    &__case-cart-button{
      button{
        padding: 0 5px;
      }
    }
  }
</style>
