<template>
  <div class="toggle-block__details-body">
    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_cryptoName'])"></div>
        <DefaultInput
            :label="$t('account_cryptoName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.cryptoName}"
            :error="account.validation.cryptoName"
            :errorTxt="$t(`${account.validationTranslate.cryptoName}.localization_value.value`)"
            v-model="account.data.cryptoName"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_cryptoWallet'])"></div>
        <DefaultInput
            :label="$t('account_cryptoWallet.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.cryptoWallet}"
            :error="account.validation.cryptoWallet"
            :errorTxt="$t(`${account.validationTranslate.cryptoWallet}.localization_value.value`)"
            v-model="account.data.cryptoWallet"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_cryptoTag'])"></div>
        <DefaultInput
            :label="$t('account_cryptoTag.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.cryptoTag}"
            :error="account.validation.cryptoTag"
            :errorTxt="$t(`${account.validationTranslate.cryptoTag}.localization_value.value`)"
            v-model="account.data.cryptoTag"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_cryptoExchangePlatform'])"></div>
        <DefaultInput
            :label="$t('account_cryptoExchangePlatform.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.cryptoExchangePlatform}"
            :error="account.validation.cryptoExchangePlatform"
            :errorTxt="$t(`${account.validationTranslate.cryptoExchangePlatform}.localization_value.value`)"
            v-model="account.data.cryptoExchangePlatform"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "Crypto",

  components: {
    DefaultInput,
  },

  props: {
    account: Object,
  },

  mounted() {
    this.account.setFieldsValid({
      cryptoName: true,
      cryptoWallet: true,
      cryptoTag: false,
      cryptoExchangePlatform: false,
    })
  },
}
</script>

<style scoped>

</style>