import { render, staticRenderFns } from "./AccountsTableUser.vue?vue&type=template&id=70fa2335&scoped=true"
import script from "./AccountsTableUser.vue?vue&type=script&lang=js"
export * from "./AccountsTableUser.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70fa2335",
  null
  
)

export default component.exports