import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {ACCOUNT_BANK_WISE, CURRENCIES} from "../../../../staticData/staticVariables";
// import {ATBusiness} from "./Business/Business";
// import {ATPersonalUkrBank} from "./PersonalUkrainianBank/PersonalUkrainianBank";
// import {ATWorldBank} from "./WorldBankAndPlatform/WorldBankAndPlatform";
// import {ATAnotherUser} from "./ToAnotherUser/ToAnotherUser";
// import {ACCOUNT_BANK_TYPE} from "../../../../../staticData/staticVariables";
// const _ = require('lodash');


export function Account() {

  this.checkValid = checkValid

  this.data = {
    typeId: '',
    bankId: '',
    userId: '',
    fullName: '',
    firstName: '',
    lastName: '',
    middleName: '',
    phone: '',
    email: '',
    city: '',
    address: '',
    state: '',
    usreou: '',
    iban: '',
    cardNumber: '',
    accountNumber: '',
    mfo: '',
    correspondentBankName: '',
    correspondentBankSwift: '',
    beneficiaryBankName: '',
    beneficiaryBankSwift: '',
    cardIdNumber: '',
    inArchive: false,
    currencyId: '',

    currency:{
      usd: CURRENCIES.USD.id,
      uah: CURRENCIES.UAH.id,
    },
    typeSelected: '',
    fieldsValid: {},
    user: '',
    accountItem: {},
    id: '',

    //WISE specific
    recipientType: '',
    forUsa: false,
    routingNumber: '',
    accountType: '',
    countryId: '',
    postCode: '',
    //WISE specific end

    //Crypto specific
    cryptoName: '',
    cryptoWallet: '',
    cryptoTag: '',
    cryptoExchangePlatform: '',
    historyOfChanged: null,
    //Crypto specific end


    // business: new ATBusiness(),
    // ukrainianBank: new ATPersonalUkrBank(),
    // worldBank: new ATWorldBank(),
    // anotherUser: new ATAnotherUser(),
  }

  this.validation = {
    typeId: false,
    bankId: false,
    userId: false,
    fullName: false,
    firstName: false,
    lastName: false,
    middleName: false,
    phone: false,
    email: false,
    city: false,
    address: false,
    state: false,
    usreou: false,
    iban: false,
    cardNumber: false,
    accountNumber: false,
    mfo: false,
    correspondentBankName: false,
    correspondentBankSwift: false,
    beneficiaryBankName: false,
    beneficiaryBankSwift: false,
    cardIdNumber: false,
    inArchive: false,
    currencyId: false,
    typeSelected: false,
    //wise
    recipientType: false,
    forUsa: false,
    routingNumber: false,
    accountType: false,
    countryId: false,
    postCode: false,
    cryptoName: false,
    cryptoWallet: false,
    cryptoTag: false,
    cryptoExchangePlatform: false,
  }

  this.validationTranslate = {
    typeId: '',
    bankId: '',
    userId: '',
    fullName: '',
    firstName: '',
    lastName: '',
    middleName: '',
    phone: '',
    email: '',
    city: '',
    address: '',
    state: '',
    usreou: '',
    iban: '',
    cardNumber: '',
    accountNumber: '',
    mfo: '',
    correspondentBankName: '',
    correspondentBankSwift: '',
    beneficiaryBankName: '',
    beneficiaryBankSwift: '',
    cardIdNumber: '',
    inArchive: '',
    currencyId: '',
    typeSelected: '',
    //wise
    recipientType: '',
    forUsa: '',
    routingNumber: '',
    accountType: '',
    countryId: '',
    postCode: '',
    cryptoName: '',
    cryptoWallet: '',
    cryptoTag: '',
    cryptoExchangePlatform: '',
  }

  this.validationTxt = {
    typeId: false,
    bankId: false,
    userId: false,
    fullName: false,
    firstName: false,
    lastName: false,
    middleName: false,
    phone: false,
    email: false,
    city: false,
    address: false,
    state: false,
    usreou: false,
    iban: false,
    cardNumber: false,
    accountNumber: false,
    mfo: false,
    correspondentBankName: false,
    correspondentBankSwift: false,
    beneficiaryBankName: false,
    beneficiaryBankSwift: false,
    cardIdNumber: false,
    inArchive: false,
    currencyId: false,
    typeSelected: false,
    //wise
    recipientType: false,
    forUsa: false,
    routingNumber: false,
    accountType: false,
    countryId: false,
    postCode: false,
    cryptoName: false,
    cryptoWallet: false,
    cryptoTag: false,
    cryptoExchangePlatform: false,
  }

  /**
   * Getters
   */
  this.getTypeId = () => {
    return this.data.typeId
  }
  this.getBankId = () => {
    return this.data.bankId
  }
  this.getUserId = () => {
    return this.data.userId
  }
  this.getFullName = () => {
    return this.data.fullName
  }
  this.getFirstName = () => {
    return this.data.firstName
  }
  this.getLastName = () => {
    return this.data.lastName
  }
  this.getMiddleName = () => {
    return this.data.middleName
  }
  this.getPhone = () => {
    return this.data.phone
  }
  this.getEmail = () => {
    return this.data.email
  }
  this.getCity = () => {
    return this.data.city
  }
  this.getAddress = () => {
    return this.data.address
  }
  this.getState = () => {
    return this.data.state
  }
  this.getUsreou = () => {
    return this.data.usreou
  }
  this.getIban = () => {
    return this.data.iban
  }
  this.getCardNumber = () => {
    return this.data.cardNumber
  }
  this.getAccountNumber = () => {
    return this.data.accountNumber
  }
  this.getMfo = () => {
    return this.data.mfo
  }
  this.getCorrespondentBankName = () => {
    return this.data.correspondentBankName
  }
  this.getCorrespondentBankSwift = () => {
    return this.data.correspondentBankSwift
  }
  this.getBeneficiaryBankName = () => {
    return this.data.beneficiaryBankName
  }
  this.getBeneficiaryBankSwift = () => {
    return this.data.beneficiaryBankSwift
  }
  this.getCardIdNumber = () => {
    return this.data.cardIdNumber
  }
  this.getInArchive = () => {
    return this.data.inArchive
  }
  this.getCurrencyId = () => {
    return this.data.currencyId
  }
  this.getTypeSelected = () => {
    return this.data.typeSelected
  }
  this.getFieldsValid = () => {
    return this.data.fieldsValid
  }
  this.getUser = () => {
    return this.data.user
  }
  this.getAccountItem = () => {
    return this.data.accountItem
  }
  this.getId = () => {
    return this.data.id
  }
  //wise
  this.getRecipientType = () => {
    return this.data.recipientType
  }
  this.getForUsa = () => {
    return this.data.forUsa
  }
  this.getRoutingNumber = () => {
    return this.data.routingNumber
  }
  this.getAccountType = () => {
    return this.data.accountType
  }
  this.getCountryId = () => {
    return this.data.countryId
  }
  this.getPostCode = () => {
    return this.data.postCode
  }
  //crypto
  this.getCryptoName = () => {
    return this.data.cryptoName
  }
  this.getCryptoWallet = () => {
    return this.data.cryptoWallet
  }
  this.getCryptoTag = () => {
    return this.data.cryptoTag
  }
  this.getCryptoExchangePlatform = () => {
    return this.data.cryptoExchangePlatform
  }
  this.getHistoryOfChanged = () => {
    return this.data.historyOfChanged
  }


  /**
   * Setters
   */
  this.setTypeId = (val) => {
    this.data.typeId = val
  }
  this.setBankId = (val) => {
    this.data.bankId = val
  }
  this.setUserId = (val) => {
    this.data.userId = val
  }
  this.setFullName = (val) => {
    this.data.fullName = val
  }
  this.setFirstName = (val) => {
    this.data.firstName = val
  }
  this.setLastName = (val) => {
    this.data.lastName = val
  }
  this.setMiddleName = (val) => {
    this.data.middleName = val
  }
  this.setPhone = (val) => {
    this.data.phone = val
  }
  this.setEmail = (val) => {
    this.data.email = val
  }
  this.setCity = (val) => {
    this.data.city = val
  }
  this.setAddress = (val) => {
    this.data.address = val
  }
  this.setState = (val) => {
    this.data.state = val
  }
  this.setUsreou = (val) => {
    this.data.usreou = val
  }
  this.setIban = (val) => {
    this.data.iban = val
  }
  this.setCardNumber = (val) => {
    this.data.cardNumber = val
  }
  this.setAccountNumber = (val) => {
    this.data.accountNumber = val
  }
  this.setMfo = (val) => {
    this.data.mfo = val
  }
  this.setCorrespondentBankName = (val) => {
    this.data.correspondentBankName = val
  }
  this.setCorrespondentBankSwift = (val) => {
    this.data.correspondentBankSwift = val
  }
  this.setBeneficiaryBankName = (val) => {
    this.data.beneficiaryBankName = val
  }
  this.setBeneficiaryBankSwift = (val) => {
    this.data.beneficiaryBankSwift = val
  }
  this.setCardIdNumber = (val) => {
    this.data.cardIdNumber = val
  }
  this.setInArchive = (val) => {
    this.data.inArchive = val
  }
  this.setCurrencyId = (val) => {
    this.data.currencyId = val
  }
  this.setTypeSelected = (val) => {
    this.setBankId(val.id)
    this.data.typeSelected = val
  }
  this.setFieldsValid = (val) => {
    this.data.fieldsValid = val
  }
  this.setUser = (val) => {
    this.setUserId(val.id)
    this.data.user = val
  }
  this.setAccountItem = (val) => {
    console.log('this.setAccountItem: ', val);
    this.data.accountItem = val
  }
  this.setId = (val) => {
    this.data.id = val
  }
  //wise
  this.setRecipientType = (val) => {
    this.data .recipientType= val
  }
  this.setForUsa = (val) => {
    this.data.forUsa = val
  }
  this.setRoutingNumber = (val) => {
    this.data.routingNumber = val
  }
  this.setAccountType = (val) => {
    this.data.accountType = val
  }
  this.setCountryId = (val) => {
    this.data.countryId = val
  }
  this.setPostCode = (val) => {
    this.data.postCode = val
  }
  //crypto
  this.setCryptoName = (val) => {
    this.data.cryptoName = val
  }
  this.setCryptoWallet = (val) => {
    this.data.cryptoWallet = val
  }
  this.setCryptoTag = (val) => {
    this.data.cryptoTag = val
  }
  this.setCryptoExchangePlatform = (val) => {
    this.data.cryptoExchangePlatform = val
  }
  this.setHistoryOfChanged = (val) => {
    this.data.historyOfChanged = val
  }
}


/**
 * Global Setters
 */

Account.prototype.setItem = function (val) {
  console.log('Account.prototype.setItem', val);
  this.setTypeId(val["type_id"] ? val["type_id"]  : null)
  this.setBankId(val["bank_id"] ? val["bank_id"]  : null)
  this.setUserId(val["user_id"] ? val["user_id"]  : null)
  this.setFullName(val["full_name"] ? val["full_name"]  : null)
  this.setFirstName(val["first_name"] ? val["first_name"]  : null)
  this.setLastName(val["last_name"] ? val["last_name"]  : null)
  this.setMiddleName(val["middle_name"] ? val["middle_name"]  : null)
  this.setPhone(val["phone"] ? val["phone"]  : null)
  this.setEmail(val["email"] ? val["email"]  : null)
  this.setCity(val["city"] ? val["city"]  : null)
  this.setAddress(val["address"] ? val["address"]  : null)
  this.setState(val["state"] ? val["state"]  : null)
  this.setUsreou(val["usreou"] ? val["usreou"]  : null)
  this.setIban(val["iban"] ? val["iban"]  : null)
  this.setCardNumber(val["card_number"] ? val["card_number"]  : null)
  this.setAccountNumber(val["account_number"] ? val["account_number"]  : null)
  this.setMfo(val["mfo"] ? val["mfo"]  : null)
  this.setCorrespondentBankName(val["correspondent_bank_name"] ? val["correspondent_bank_name"]  : null)
  this.setCorrespondentBankSwift(val["correspondent_bank_swift"] ? val["correspondent_bank_swift"]  : null)
  this.setBeneficiaryBankName(val["beneficiary_bank_name"] ? val["beneficiary_bank_name"]  : null)
  this.setBeneficiaryBankSwift(val["beneficiary_bank_swift"] ? val["beneficiary_bank_swift"]  : null)
  this.setCardIdNumber(val["card_id_number"] ? val["card_id_number"]  : null)
  this.setInArchive(val["in_archive"] ? val["in_archive"]  : null)
  this.setCurrencyId(val["currency_id"] ? val["currency_id"]  : null)

  this.setTypeSelected(val["bank"] ? val["bank"]  : null)
  this.setId(val["id"] ? val["id"]  : null)
  this.setAccountItem(val ? val : '')

  //wise
  this.setRecipientType(val["recipient_type"] ? {name: val["recipient_type"], value: val["recipient_type"]} : null)
  this.setAccountType(val["checking_type"] ? {name: val["checking_type"], value: val["checking_type"]} : null)
  this.setForUsa(val["for_usa"] === 1)
  this.setCountryId(val["country_id"] ? val["country_id"] : null)
  this.setRoutingNumber(val["routing_code"] ? val["routing_code"] : null)
  this.setPostCode(val["post_code"] ? val["post_code"] : null)

  //crypto
  this.setCryptoName(val["crypto_name"] ? val["crypto_name"] : null)
  this.setCryptoWallet(val["crypto_wallet"] ? val["crypto_wallet"] : null)
  this.setCryptoTag(val["crypto_tag"] ? val["crypto_tag"] : null)
  this.setCryptoExchangePlatform(val["crypto_exchange_platform"] ? val["crypto_exchange_platform"] : null)
  this.setHistoryOfChanged(val?.activityUUID)
}


/**
 * Functions
 */

Account.prototype.resetFields = function (){
  this.setFullName('')
  this.setFirstName('')
  this.setLastName('')
  this.setMiddleName('')
  this.setPhone('')
  this.setEmail('')
  this.setCity('')
  this.setAddress('')
  this.setState('')
  this.setUsreou('')
  this.setIban('')
  this.setCardNumber('')
  this.setAccountNumber('')
  this.setMfo('')
  this.setCorrespondentBankName('')
  this.setCorrespondentBankSwift('')
  this.setBeneficiaryBankName('')
  this.setBeneficiaryBankSwift('')
  this.setCardIdNumber('')
  //wise
  this.setRecipientType('')
  this.setForUsa(false)
  this.setRoutingNumber('')
  this.setAccountType('')
  this.setCountryId('')
  this.setPostCode('')
  //crypto
  this.setCryptoName('')
  this.setCryptoWallet('')
  this.setCryptoTag('')
  this.setCryptoExchangePlatform('')
}


Account.prototype.changeBank = function (item){
  // console.log(111, item.id, this.getAccountItem()["bank_id"]);
  if(item.id === this.getAccountItem()["bank_id"]){
    this.setItem(this.getAccountItem())
  } else {
    this.resetFields()
  }
  this.setBankId(item.id)
}

Account.prototype.setAccountUser = function (user) {
  // let userInfo
  //
  // this.data.user = user

  this.setUserId(user.id)

  // if (user?.contacts) {
  //   userInfo = _.first(user?.contacts)
  // } else {
  //   userInfo = user?.user_personal_contact
  // }
  //
  // this.writingDataCurrentUser(userInfo)
}

/**
 * Validations
 */

Account.prototype.firstValidation = function ({
    typeId,
    bankId,
    userId,
    fullName,
    firstName,
    lastName,
    middleName,
    phone,
    email,
    city,
    address,
    state,
    usreou,
    iban,
    ibanUa,
    cardNumber,
    accountNumber,
    mfo,
    correspondentBankName,
    correspondentBankSwift,
    beneficiaryBankName,
    beneficiaryBankSwift,
    cardIdNumber,
    inArchive,
    currencyId,
    typeSelected,
    recipientType,
    routingNumber,
    accountType,
    countryId,
    cryptoName,
    cryptoWallet,
    cryptoTag,
    cryptoExchangePlatform,
  }) {

  console.log(phone);

  let data = this.data


  let validationItems = {
    typeId: data.typeId,
    bankId: data.bankId,
    userId: data.userId,
    fullName: data.fullName,
    firstName: data.firstName,
    lastName: data.lastName,
    middleName: data.middleName,
    phone: data.phone,
    email: data.email,
    city: data.city,
    address: data.address,
    state: data.state,
    usreou: data.usreou,
    iban: data.iban,
    cardNumber: data.cardNumber,
    accountNumber: data.accountNumber,
    mfo: data.mfo,
    correspondentBankName: data.correspondentBankName,
    correspondentBankSwift: data.correspondentBankSwift,
    beneficiaryBankName: data.beneficiaryBankName,
    beneficiaryBankSwift: data.beneficiaryBankSwift,
    cardIdNumber: data.cardIdNumber,
    inArchive: data.inArchive,
    currencyId: data.currencyId,
    typeSelected: data.typeSelected,
    recipientType: data.recipientType,
    routingNumber: data.routingNumber,
    accountType: data.accountType,
    countryId: data.countryId,
    cryptoName: data.cryptoName,
    cryptoWallet: data.cryptoWallet,
    cryptoTag: data.cryptoTag,
    cryptoExchangePlatform: data.cryptoExchangePlatform,
  }

  let validationOptions = {
    typeId: {type: typeId ? ['empty'] : []},
    bankId: {type: bankId ? ['empty'] : []},
    userId: {type: userId ? ['empty'] : []},
    fullName: {type: fullName ? ['empty'] : []},
    firstName: {type: firstName ? ['empty'] : []},
    lastName: {type: lastName ? ['empty'] : []},
    middleName: {type: middleName ? ['empty'] : []},
    phone: {type: phone ? ['empty', 'phone'] : []},
    email: {type: email ? ['empty', 'email'] : []},
    city: {type: city ? ['empty'] : []},
    address: {type: address ? ['empty'] : []},
    state: {type: state ? ['empty'] : []},
    usreou: {type: usreou ? ['empty'] : []},
    iban: {type: iban ? ['empty'] : []},
    cardNumber: {type: cardNumber ? ['empty', 'numeric'] : []},
    accountNumber: {type: accountNumber ? ['empty'] : []},
    mfo: {type: mfo ? ['empty'] : []},
    correspondentBankName: {type: correspondentBankName ? ['empty'] : []},
    correspondentBankSwift: {type: correspondentBankSwift ? ['empty'] : []},
    beneficiaryBankName: {type: beneficiaryBankName ? ['empty'] : []},
    beneficiaryBankSwift: {type: beneficiaryBankSwift ? ['empty'] : []},
    cardIdNumber: {type: cardIdNumber ? ['empty'] : []},
    inArchive: {type: inArchive ? ['empty'] : []},
    currencyId: {type: currencyId ? ['empty'] : []},
    typeSelected: {type: typeSelected ? ['empty'] : []},
    recipientType: {type: recipientType ? ['empty'] : []},
    routingNumber: {type: routingNumber ? ['empty'] : []},
    accountType: {type: accountType ? ['empty'] : []},
    countryId: {type: countryId ? ['empty'] : []},
    cryptoName: {type: cryptoName ? ['empty'] : []},
    cryptoWallet: {type: cryptoWallet ? ['empty'] : []},
    cryptoTag: {type: cryptoTag ? ['empty'] : []},
    cryptoExchangePlatform: {type: cryptoExchangePlatform ? ['empty'] : []},
  }


  let valid = this.checkValid(validationItems, validationOptions)

  if(ibanUa && (!data.iban || data.iban.length !== 29 || data.iban.indexOf('UA') !== 0)) {
    this.validation.iban = true
    this.validationTranslate.iban = 'error_iban'
    valid = false
  } else {
    this.validation.iban = false
    this.validationTranslate.iban = ''
  }

  return valid
  // return valid

}


/**
 * Prepare Data
 */

Account.prototype.prepareSave = function () {



  let data = {
    "type_id": this.getTypeId() ? this.getTypeId() : null,
    "bank_id": this.getBankId() ? this.getBankId() : null,
    "user_id": this.getUserId() ? this.getUserId() : null,
    "full_name": this.getFullName() ? this.getFullName() : null,
    "first_name": this.getFirstName() ? this.getFirstName() : null,
    "last_name": this.getLastName() ? this.getLastName() : null,
    "middle_name": this.getMiddleName() ? this.getMiddleName() : null,
    "phone": this.getPhone() ? this.getPhone() : null,
    "email": this.getEmail() ? this.getEmail() : null,
    "city": this.getCity() ? this.getCity() : null,
    "address": this.getAddress() ? this.getAddress() : null,
    "state": this.getState() ? this.getState() : null,
    "usreou": this.getUsreou() ? this.getUsreou() : null,
    "iban": this.getIban() ? (this.getBankId() === ACCOUNT_BANK_WISE.id ? this.getIban() : this.getIban().replace(/\s/g, '')) : null,
    "card_number": this.getCardNumber() ? this.getCardNumber().replace(/\s/g, '') : null,
    "account_number": this.getAccountNumber() ? this.getAccountNumber() : null,
    "mfo": this.getMfo() ? this.getMfo() : null,
    "correspondent_bank_name": this.getCorrespondentBankName() ? this.getCorrespondentBankName() : null,
    "correspondent_bank_swift": this.getCorrespondentBankSwift() ? this.getCorrespondentBankSwift() : null,
    "beneficiary_bank_name": this.getBeneficiaryBankName() ? this.getBeneficiaryBankName() : null,
    "beneficiary_bank_swift": this.getBeneficiaryBankSwift() ? this.getBeneficiaryBankSwift() : null,
    "card_id_number": this.getCardIdNumber() ? this.getCardIdNumber() : null,
    "in_archive": this.getInArchive() ? this.getInArchive() : false,
    "currency_id": this.getCurrencyId() ? this.getCurrencyId() : null,

    "for_usa": this.getForUsa(),
    "recipient_type": this.getRecipientType() ? this.getRecipientType().name : null,
    "routing_code": this.getRoutingNumber() ? this.getRoutingNumber() : null,
    "checking_type": this.getAccountType() ? this.getAccountType().name : null,
    "country_id": this.getCountryId() ? this.getCountryId() : null,
    "post_code": this.getPostCode() ? this.getPostCode() : null,

    "crypto_name": this.getCryptoName() ? this.getCryptoName() : null,
    "crypto_wallet": this.getCryptoWallet() ? this.getCryptoWallet() : null,
    "crypto_tag": this.getCryptoTag() ? this.getCryptoTag() : null,
    "crypto_exchange_platform": this.getCryptoExchangePlatform() ? this.getCryptoExchangePlatform() : null,
  }

  return data
}
