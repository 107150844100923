<template>
  <div class="order-create__section">

    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['account_bankType'])"></div>
      {{ $t('account_bankType.localization_value.value') }}
    </div>
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__toggle-block toggle-block">
          <div class="toggle-block__type">
            <div class="toggle-block__type-list">
              <div class="toggle-block__type-item"
                   v-for="(item, index) in banks"
                   :key="index"
              >
                <RadioLabelPicture
                        v-if="!(item.id === ACCOUNT_BANK_KIT_GROUP.id && !(isAdmin || getUserAccessKitGroup))"
                        :label="item.currentTranslate.name"
                        name="pay-system"
                        @change="changePayType(item)"
                        :value="account.getBankId() === item.id"
                >
                  <template slot="ico">
                    <img v-if="item.logo_base64" :src="item.logo_base64" alt="ico" height="18">
                  </template>
                </RadioLabelPicture>
              </div>
            </div>
          </div>
          <div class="toggle-block__details" v-if="account.getBankId()">
            <div class="toggle-block__details-head">
              <div class="toggle-block__details-title"
                   v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['account_accountDetail'])"></div>
                {{ $t('account_accountDetail.localization_value.value') }}
              </div>
            </div>

            <Payoneer
                    :account="account"
                    v-if="account.getBankId() === ACCOUNT_BANK_PAYONEER.id"
            />

            <PayPal
                    :account="account"
                    v-else-if="account.getBankId() === ACCOUNT_BANK_PAYPAL.id"
            />

            <Swift
                    :account="account"
                    v-else-if="account.getBankId() === ACCOUNT_BANK_SWIFT.id"
            />

            <PayoneerSecond
                    :account="account"
                    v-else-if="account.getBankId() === ACCOUNT_BANK_PAYONEER_SECOND.id"
            />

            <Epayments
                    :account="account"
                    v-else-if="account.getBankId() === ACCOUNT_BANK_EPAYMENTS.id"
            />

            <Bento
                    :account="account"
                    v-else-if="account.getBankId() === ACCOUNT_BANK_BENTO_VISA_CARD.id"
            />

            <Crypto
                :account="account"
                v-else-if="account.getBankId() === ACCOUNT_BANK_CRYPTO.id"
            />

            <KitGroup
                    :account="account"
                    v-else-if="account.getBankId() === ACCOUNT_BANK_KIT_GROUP.id && (isAdmin || getUserAccessKitGroup)"
            />
            <RiaInternational
                    :account="account"
                    v-else-if="account.getBankId() === ACCOUNT_BANK_RIA_INTERNATIONAL.id"
            />

            <Wise
                :account="account"
                :createData="wiseCreateData"
                v-else-if="account.getBankId() === ACCOUNT_BANK_WISE.id && wiseDataLoad"
            />

            <Ramp
                :account="account"
                v-else-if="account.getBankId() === ACCOUNT_BANK_RAMP.id"
            />

            <DotsShadowPreloader
                v-else-if="account.getBankId() === ACCOUNT_BANK_WISE.id && !wiseDataLoad"
            />

            <WarningStatic v-else class="mw-100 ">
              <template slot="text">
              <span
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit" @click="editTranslate(['account_bankWarning'])"></span>
                {{ $t('account_bankWarning.localization_value.value') }}
              </span>

              </template>
            </WarningStatic>

          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
  import RadioLabelPicture from "../../../../../UI/radiobuttons/RadioLabelPicture/RadioLabelPicture";
  import Swift from "./components/Swift";
  import {
    ACCOUNT_BANK_BENTO_VISA_CARD,
    ACCOUNT_BANK_EPAYMENTS,
    ACCOUNT_BANK_KIT_GROUP,
    ACCOUNT_BANK_PAYONEER,
    ACCOUNT_BANK_PAYONEER_SECOND,
    ACCOUNT_BANK_PAYPAL,
    ACCOUNT_BANK_RIA_INTERNATIONAL,
    ACCOUNT_BANK_SWIFT,
    ACCOUNT_BANK_TYPE,
    ACCOUNT_BANK_WISE,
    ACCOUNT_BANK_RAMP,
    ACCOUNT_BANK_CRYPTO
  } from "../../../../../../staticData/staticVariables";
  import Payoneer from "./components/Payoneer";
  import PayPal from "./components/PayPal";
  import PayoneerSecond from "./components/PayoneerSecond";
  import Epayments from "./components/Epayments";
  import Bento from "./components/Bento";
  import KitGroup from "./components/KitGroup";
  import RiaInternational from "./components/RiaInternational";
  import WarningStatic from "../../../../../UI/alerts/WarningStatic/WarningStatic";
  import Wise from "./components/Wise";
  import DotsShadowPreloader from "../../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
  import Crypto from "./components/Crypto";
  import Ramp from "./components/Ramp";

  export default {
    name: "AccountWorldBank",

    components: {
      Crypto,
      DotsShadowPreloader,
      Wise,
      RiaInternational,
      KitGroup,
      Bento,
      Ramp,
      Epayments,
      PayoneerSecond,
      PayPal,
      Payoneer,
      Swift,
      RadioLabelPicture,
      WarningStatic,
    },

    props: {
      account: Object,
    },

    //fix for edit
    watch: {
      'account.data.id': function() {
        this.getWiseAccountCreate()
      },
    },

    data() {
      return {
        ACCOUNT_BANK_PAYONEER: ACCOUNT_BANK_PAYONEER,
        ACCOUNT_BANK_PAYPAL: ACCOUNT_BANK_PAYPAL,
        ACCOUNT_BANK_SWIFT: ACCOUNT_BANK_SWIFT,
        ACCOUNT_BANK_PAYONEER_SECOND: ACCOUNT_BANK_PAYONEER_SECOND,
        ACCOUNT_BANK_EPAYMENTS: ACCOUNT_BANK_EPAYMENTS,
        ACCOUNT_BANK_BENTO_VISA_CARD: ACCOUNT_BANK_BENTO_VISA_CARD,
        ACCOUNT_BANK_KIT_GROUP: ACCOUNT_BANK_KIT_GROUP,
        ACCOUNT_BANK_RIA_INTERNATIONAL: ACCOUNT_BANK_RIA_INTERNATIONAL,
        ACCOUNT_BANK_WISE: ACCOUNT_BANK_WISE,
        ACCOUNT_BANK_CRYPTO: ACCOUNT_BANK_CRYPTO,
        ACCOUNT_BANK_RAMP: ACCOUNT_BANK_RAMP,

        // selectedBankId: '',

        email: '',
        radioVal: 1,

        checkedBank: 1,

        banks: [],

        wiseDataLoad: false,
        wiseCreateData: {}
      }
    },

    mounted() {
      this.getAccountBanksWorld()
    },

    created() {
      // if (!this.$route.params.id) {
      //   this.account.setBankId(ACCOUNT_BANK_PAYONEER.id)
      // }
    },

    methods: {
      changePayType(val) {
        this.account.changeBank(val)

        if (this.account.getBankId() === ACCOUNT_BANK_WISE.id && !this.wiseDataLoad) {
          this.getWiseAccountCreate()
        }
      },

      getAccountBanksWorld() {
        const query = new this.Query();
        let url = '?';
        let myQuery = query
          .for('posts')

        myQuery.where('type_id', ACCOUNT_BANK_TYPE.WORLD_BANK.id)

        /**
         * Withdraw banks only with the option enabled
         */
        myQuery.where('enable', true)

        url = url + myQuery.limit(100000).page(0).url().split('?')[1]

        this.$store.dispatch('fetchAccountBanks', url).then(() => {
          this.banks = this.$store.getters.getAccountBanks
        })
      },

      getWiseAccountCreate() {
        this.$store.dispatch('getWiseAccountCreate').then((response) => {
          this.wiseCreateData = this.getRespData(response).initial_params

          this.wiseCreateData.wise_recipient_types = this.wiseCreateData.wise_recipient_types.map(item => {
            return {
              name: item,
              label: item
            }
          })

          this.wiseCreateData.checking_types = this.wiseCreateData.checking_types.map(item => {
            return {
              name: item,
              label: item
            }
          })

          this.wiseDataLoad = true
        }).catch(error => this.createErrorLog(error))
      },

    },

  }
</script>

<style scoped lang="scss">
  .toggle-block__details {
    @media (max-width: 992px) {
      min-height: auto;
    }
  }
</style>
