<template>
  <div class="toggle-block__details-body">
    <div class="custom-row">
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_firstName'])"></div>
        <DefaultInput
            :label="$t('account_firstName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.firstName}"
            :error="account.validation.firstName"
            :errorTxt="$t(`${account.validationTranslate.firstName}.localization_value.value`)"
            v-model="account.data.firstName"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_lastName'])"></div>
        <DefaultInput
            :label="$t('account_lastName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.lastName}"
            :error="account.validation.lastName"
            :errorTxt="$t(`${account.validationTranslate.lastName}.localization_value.value`)"
            v-model="account.data.lastName"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_middleName'])"></div>
        <DefaultInput
            :label="$t('account_middleName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.middleName}"
            :error="account.validation.middleName"
            :errorTxt="$t(`${account.validationTranslate.middleName}.localization_value.value`)"
            v-model="account.data.middleName"
        />
      </div>
      <div class="custom-col mb-0"></div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_city'])"></div>
        <DefaultInput
            :label="$t('account_city.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.city}"
            :error="account.validation.city"
            :errorTxt="$t(`${account.validationTranslate.city}.localization_value.value`)"
            v-model="account.data.city"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_address'])"></div>
        <DefaultInput
            :label="$t('account_address.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.address}"
            :error="account.validation.address"
            :errorTxt="$t(`${account.validationTranslate.address}.localization_value.value`)"
            v-model="account.data.address"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_state'])"></div>
        <DefaultInput
            :label="$t('account_state.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.state}"
            :error="account.validation.state"
            :errorTxt="$t(`${account.validationTranslate.state}.localization_value.value`)"
            v-model="account.data.state"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "RiaInternational",

  components: {
    DefaultInput,
  },

  props: {
    account: Object,
  },

  mounted() {
    this.account.setFieldsValid({
      firstName: true,
      lastName: true,
      middleName: false,
      city: true,
      address: true,
      state: true,
    })
  },
}
</script>

<style scoped>

</style>