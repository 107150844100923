<template>
  <div class="toggle-block__details-body">
    <div class="custom-row">
      <div class="custom-col custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_recipientName'])"></div>
        <DefaultInput
            :label="$t('account_recipientName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.fullName}"
            :error="account.validation.fullName"
            :errorTxt="$t(`${account.validationTranslate.fullName}.localization_value.value`)"
            v-model="account.data.fullName"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_emailOptional'])"></div>
        <DefaultInput
            :label="$t('account_emailOptional.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.email}"
            :error="account.validation.email"
            :errorTxt="$t(`${account.validationTranslate.email}.localization_value.value`)"
            v-model="account.data.email"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_recipientType'])"></div>
        <DefaultSelect
            :options="createData.wise_recipient_types"
            :optionsLabel="'name'"
            :label="$t('account_recipientType.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.recipientType}"
            :errorTxt="$t(`${account.validationTranslate.recipientType}.localization_value.value`)"
            :error="account.validation.recipientType"
            :selected="account.data.recipientType"
            @change="setRecipientType"
        />
      </div>

      <div class="custom-col"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_forUsa'])"></div>
        <DefaultCheckbox
            :label="$t('account_forUsa.localization_value.value')"
            :value="account.data.forUsa"
            @input="setWiseAccountForUsa"
        />
      </div>

      <div class="custom-col custom-col--50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_currency'])"></div>
        <DefaultSelect
            :options="currentCurrencies"
            :optionsLabel="'alpha_code'"
            :label="$t('account_currency.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.currencyId}"
            :errorTxt="$t(`${account.validationTranslate.currencyId}.localization_value.value`)"
            :error="account.validation.currencyId"
            :selected="currentCurrency"
            @change="setCurrency"
        />
      </div>

      <div class="custom-col custom-col--50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_routingNumber', 'account_swiftBicCode'])"></div>
        <DefaultInput
            :label="account.getForUsa() ? $t('account_routingNumber.localization_value.value') : $t('account_swiftBicCode.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.routingNumber}"
            :error="account.validation.routingNumber"
            :errorTxt="$t(`${account.validationTranslate.routingNumber}.localization_value.value`)"
            v-model="account.data.routingNumber"
        />
      </div>

      <div class="custom-col custom-col--50 custom-col--sm-100"
           v-if="account.getForUsa()"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_accountNumber'])"></div>
        <DefaultInput
            :label="$t('account_accountNumber.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.accountNumber}"
            :error="account.validation.accountNumber"
            :errorTxt="$t(`${account.validationTranslate.accountNumber}.localization_value.value`)"
            v-model="account.data.accountNumber"
        />
      </div>

      <div class="custom-col custom-col--50 custom-col--sm-100"
           v-if="!account.getForUsa()"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_iBan'])"></div>
        <DefaultInput
            :label="$t('account_iBan.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.iban}"
            :error="account.validation.iban"
            :errorTxt="$t(`${account.validationTranslate.iban}.localization_value.value`)"
            v-model="account.data.iban"
        />
      </div>

      <div class="custom-col custom-col--50 custom-col--sm-100"
           v-if="account.getForUsa()"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_accountType'])"></div>
        <DefaultSelect
            :options="createData.checking_types"
            :optionsLabel="'name'"
            :label="$t('account_accountType.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.accountType}"
            :errorTxt="$t(`${account.validationTranslate.accountType}.localization_value.value`)"
            :error="account.validation.accountType"
            :selected="account.data.accountType"
            @change="setAccountType"
        />
      </div>

      <div class="custom-col custom-col--50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
        <DefaultSelect
            :options="currentCountries"
            :optionsLabel="'name'"
            :otherValue="'currentTranslation'"
            :label="$t('common_country.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.countryId}"
            :errorTxt="$t(`${account.validationTranslate.countryId}.localization_value.value`)"
            :error="account.validation.countryId"
            :selected="currentCountry"
            @change="setCountry"
        />
      </div>

      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_city'])"></div>
        <DefaultInput
            :label="$t('account_city.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.city}"
            :error="account.validation.city"
            :errorTxt="$t(`${account.validationTranslate.city}.localization_value.value`)"
            v-model="account.data.city"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_address'])"></div>
        <DefaultInput
            :label="$t('account_address.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.address}"
            :error="account.validation.address"
            :errorTxt="$t(`${account.validationTranslate.address}.localization_value.value`)"
            v-model="account.data.address"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-if="account.getForUsa()"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_state'])"></div>
        <DefaultSelect
            :options="createData.usa_states"
            :optionsLabel="'code'"
            :label="$t('account_state.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.state}"
            :error="account.validation.state"
            :errorTxt="$t(`${account.validationTranslate.state}.localization_value.value`)"
            :selected="_.find(createData.usa_states, {code: account.data.state})"
            @change="setState"
        />
      </div>

      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_postCode'])"></div>
        <DefaultInput
            :label="$t('account_postCode.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.postCode}"
            :error="account.validation.postCode"
            :errorTxt="$t(`${account.validationTranslate.postCode}.localization_value.value`)"
            v-model="account.data.postCode"
        />
      </div>

    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import {CURRENCIES, USA_COUNTRY_ID} from "../../../../../../../staticData/staticVariables";

export default {
  name: "Wise",

  components: {
    DefaultCheckbox,
    DefaultSelect,
    DefaultInput,
  },

  props: {
    account: Object,
    createData: Object,
  },

  mounted() {
    this.account.setFieldsValid({
      fullName: true,
      email: false,
      recipientType: true,
      currencyId: true,
      routingNumber: true,
      accountNumber: this.account.getForUsa(),
      iban: !this.account.getForUsa(),
      accountType: this.account.getForUsa(),
      countryId: true,
      state: this.account.getForUsa(),
    })

    this.currenciesForNonUsa = this.createData.wise_currencies
    this.currenciesForUsa = this.createData.wise_currencies.filter(item => {
      return item.id === CURRENCIES.USD.id
    })

    this.countriesForNonUsa = this.createData.wise_countries.filter(item => {
      return item.id !== USA_COUNTRY_ID
    })
    this.countriesForUsa = this.createData.wise_countries.filter(item => {
      return item.id === USA_COUNTRY_ID
    })

    if (this.account.getForUsa()) {
      this.currentCurrencies = this.currenciesForUsa
      this.currentCountries = this.countriesForUsa
    }
    else {
      this.currentCurrencies = this.currenciesForNonUsa
      this.currentCountries = this.countriesForNonUsa
    }

    if (this.account.getCurrencyId()) {
      this.currentCurrency = this.currentCurrencies.find(item => {
        return item.id === this.account.getCurrencyId()
      })
    }

    if (this.account.getCountryId()) {
      this.currentCountry = this.currentCountries.find(item => {
        return item.id === this.account.getCountryId()
      })
    }
  },

  data() {
    return {
      currentCurrency: null,
      currentCurrencies: [],
      currenciesForUsa: [],
      currenciesForNonUsa: [],
      currentCountry: null,
      currentCountries: [],
      countriesForUsa: [],
      countriesForNonUsa: [],
    }
  },

  methods: {
    setRecipientType(val) {
      this.account.setRecipientType(val)
    },

    setWiseAccountForUsa(val) {
      this.account.setForUsa(val)

      this.currentCurrencies = val ? this.currenciesForUsa : this.currenciesForNonUsa
      this.setCurrency(this.currentCurrencies[0])
      this.currentCountries = val ? this.countriesForUsa : this.countriesForNonUsa
      this.setCountry(val ? this.currentCountries[0] : null)

      this.account.setFieldsValid({
        fullName: true,
        email: false,
        recipientType: true,
        currencyId: true,
        routingNumber: true,
        accountNumber: val,
        iban: !val,
        accountType: val,
        countryId: true,
        state: val,
      })
    },

    setCurrency(val) {
      this.currentCurrency = val
      this.account.setCurrencyId(val?.id)
    },

    setAccountType(val) {
      this.account.setAccountType(val)
    },

    setCountry(val) {
      this.currentCountry = val
      this.account.setCountryId(val?.id)
    },

    setState(val) {
      this.account.setState(val.code)
    }
  }
}
</script>

<style scoped>

</style>