<template>
  <div class="toggle-block__details-body">
    <div class="custom-row">
      <div class="custom-col custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_fullName'])"></div>
        <DefaultInput
            :label="$t('account_fullName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.fullName}"
            :error="account.validation.fullName"
            :errorTxt="$t(`${account.validationTranslate.fullName}.localization_value.value`)"
            v-model="account.data.fullName"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_correspondentBankName'])"></div>
<!--        Correspondent Bank Name-->
        <DefaultInput
            :label="$t('account_correspondentBankName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.correspondentBankName}"
            :error="account.validation.correspondentBankName"
            :errorTxt="$t(`${account.validationTranslate.correspondentBankName}.localization_value.value`)"
            v-model="account.data.correspondentBankName"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_correspondentBankSwift'])"></div>
<!--        Correspondent Bank Swift-->
        <DefaultInput
            :label="$t('account_correspondentBankSwift.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.correspondentBankSwift}"
            :error="account.validation.correspondentBankSwift"
            :errorTxt="$t(`${account.validationTranslate.correspondentBankSwift}.localization_value.value`)"
            v-model="account.data.correspondentBankSwift"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_beneficiaryBankName'])"></div>
<!--        Beneficiary Bank Name-->
        <DefaultInput
            :label="$t('account_beneficiaryBankName.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.beneficiaryBankName}"
            :error="account.validation.beneficiaryBankName"
            :errorTxt="$t(`${account.validationTranslate.beneficiaryBankName}.localization_value.value`)"
            v-model="account.data.beneficiaryBankName"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_beneficiaryBankSwift'])"></div>
<!--        Beneficiary Bank Swift-->
        <DefaultInput
            :label="$t('account_beneficiaryBankSwift.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.beneficiaryBankSwift}"
            :error="account.validation.beneficiaryBankSwift"
            :errorTxt="$t(`${account.validationTranslate.beneficiaryBankSwift}.localization_value.value`)"
            v-model="account.data.beneficiaryBankSwift"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100 "
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_accountNumber'])"></div>
        <DefaultInput
            :label="$t('account_accountNumber.localization_value.value')"
            v-bind:class="{'ui-no-valid': account.validation.accountNumber}"
            :error="account.validation.accountNumber"
            :errorTxt="$t(`${account.validationTranslate.accountNumber}.localization_value.value`)"
            v-model="account.data.accountNumber"
        />
      </div>
      <div class="custom-col custom-col--50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['account_iBan'])"></div>
<!--            v-mask="'AA ## ###### ##### #### #### ## ####'"-->
        <DefaultInput
            :label="$t('account_iBan.localization_value.value')"
            :placeholder="'_ _  _ _  _ _ _ _ _ _  _ _ _ _ _  _ _ _ _  _ _ _ _   _ _  _ _ _ _'"
            v-bind:class="{'ui-no-valid': account.validation.iban}"
            :error="account.validation.iban"
            :errorTxt="$t(`${account.validationTranslate.iban}.localization_value.value`)"
            v-model="account.data.iban"
        />
      </div>

    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "Swift",

  components: {
    DefaultInput,
  },

  props: {
    account: Object,
  },

  mounted() {
    this.account.setFieldsValid({
      fullName: true,
      correspondentBankName: true,
      correspondentBankSwift: true,
      beneficiaryBankName: true,
      beneficiaryBankSwift: true,
      accountNumber: true,
      iban: true,
    })
  },
}
</script>

<style scoped>

</style>