<template>
  <div class="fragment">
    <AccountsTableUser
        :navTabs="navTabs"
        @changeTab="changeTab"
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        @resetFilter="resetFilter"
        @changeFilter="changeFilter"
        @showMore="showMore"
        @reload="reload"
    />
  </div>
</template>

<script>
  import AccountsTableUser from "./AccountsTableUser/AccountsTableUser";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {radioButtonsFunc} from "../../../../../mixins/creationPageMixins/radioButtonsFunc";
  import {ACCOUNT_BANK_TYPE} from "../../../../../staticData/staticVariables";
  import {checkAccess} from "@/mixins/userCredentionalMixins/userCredentional";

  export default {
    name: "AccountsTable",
    components: {
      AccountsTableUser,
    },

    mixins: [routeFilter, radioButtonsFunc, checkAccess],

    data() {
      return {
        forPage: this.$store.getters.getAccountForPage,
        page: 1,
        onePage: 1,
        filterType: 'all',
        filterUserName: '',
        filterUserId: '',
        filterId: '',
        filterFullName: '',
        filterFirstName: '',
        filterLastName: '',
        filterMiddleName: '',
        filterPhone: '',
        filterEmail: '',
        filterCardNumber: '',
        filterAccountNumber: '',
        filterUsreou: '',
        filterIban: '',
        filterAddress: '',
        filterCity: '',
        filterState: '',
        filterCorrespondentBankName: '',
        filterCorrespondentBankSwift: '',
        filterBeneficiaryBankName: '',
        filterBeneficiaryBankSwift: '',
        filterCardId: '',
        // filterInArchive: '',

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          all: {
            label: this.$t('account_allAccounts.localization_value.value'),
            name: 'all',
            id: -1,
            active: true,
            permissionVisible: true,
          },
          [ACCOUNT_BANK_TYPE.UKRAINIAN_BANK.name]: {
            label: this.$t('account_personalUkraine.localization_value.value'),
            name: ACCOUNT_BANK_TYPE.UKRAINIAN_BANK.name,
            id: ACCOUNT_BANK_TYPE.UKRAINIAN_BANK.id,
            active: false,
            permissionVisible: false,
          },
          [ACCOUNT_BANK_TYPE.WORLD_BANK.name]: {
            label: this.$t('account_personalWorld.localization_value.value'),
            name: ACCOUNT_BANK_TYPE.WORLD_BANK.name,
            id: ACCOUNT_BANK_TYPE.WORLD_BANK.id,
            active: false,
            permissionVisible: false,
          },
          [ACCOUNT_BANK_TYPE.CUSTOM_BANK.name]: {
            label: this.$t('account_business.localization_value.value'),
            name: ACCOUNT_BANK_TYPE.CUSTOM_BANK.name,
            id: ACCOUNT_BANK_TYPE.CUSTOM_BANK.id,
            active: false,
            permissionVisible: false,
          },
          [ACCOUNT_BANK_TYPE.CUSTOM_ACCOUNT.name]: {
            label: this.$t('common_emails.localization_value.value'),
            name: ACCOUNT_BANK_TYPE.CUSTOM_ACCOUNT.name,
            id: ACCOUNT_BANK_TYPE.CUSTOM_ACCOUNT.id,
            active: false,
            permissionVisible: false,
          },
        },

      }
    },

    mounted() {

      if(this.$route.query.type !== undefined){
        this.navTabs.all.active = false
        this.navTabs[this.$route.query.type].active = true
      }

      if(this.downloadPermissions){
        this.checkTabPermission()
      }
    },

    methods: {

      checkTabPermission() {

        this.accessToPersonalAccount()

        this.activeTabByPermissions('navTabs', {
          [ACCOUNT_BANK_TYPE.UKRAINIAN_BANK.name]: this.PERMISSIONS.ACCOUNT_PERSONAL_UKRAINE,
          [ACCOUNT_BANK_TYPE.WORLD_BANK.name]: this.PERMISSIONS.ACCOUNT_PERSONAL_WORLD,
          [ACCOUNT_BANK_TYPE.CUSTOM_BANK.name]: this.PERMISSIONS.ACCOUNT_BUSINESS,
          [ACCOUNT_BANK_TYPE.CUSTOM_ACCOUNT.name]: this.PERMISSIONS.ACCOUNT_EMAIL,
        })

        if(!this._.find(this.navTabs, {active: true}).permissionVisible){
          this.setActivePermissionVisibleTab('navTabs')
          return
        }

        this.filter()
      },

      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,
          id: this.$route.query.id,
          fullName: this.$route.query.fullName,
          firstName: this.$route.query.firstName,
          lastName: this.$route.query.lastName,
          middleName: this.$route.query.middleName,
          phone: this.$route.query.phone,
          email: this.$route.query.email,
          cardNumber: this.$route.query.cardNumber,
          accountNumber: this.$route.query.accountNumber,
          usreou: this.$route.query.usreou,
          iban: this.$route.query.iban,
          address: this.$route.query.address,
          city: this.$route.query.city,
          state: this.$route.query.state,
          correspondentBankName: this.$route.query.correspondentBankName,
          correspondentBankSwift: this.$route.query.correspondentBankSwift,
          beneficiaryBankName: this.$route.query.beneficiaryBankName,
          beneficiaryBankSwift: this.$route.query.beneficiaryBankSwift,
          cardId: this.$route.query.cardId,
          // inArchive: this.$route.query.inArchive,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        this.$store.commit('setAccountFilter', window.location.search)

        if (next) this.$store.commit('setNextAccountPage', true)
        this.$store.dispatch('fetchAccountWithPermissions', url).then(() => {
          this.$store.commit('setNextAccountPage', false)
        })


        this.checkCountFilter(['userName', 'type'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.filterIban = this.filterIban.replaceAll(' ', '')

        this.generateFilterQueryParams(
            myQuery,
            {
              filterUserId: 'user_id',
              filterId: 'id',
              filterFullName: 'full_name',
              filterFirstName: 'first_name',
              filterLastName: 'last_name',
              filterMiddleName: 'middle_name',
              filterPhone: 'phone',
              filterEmail: 'email',
              filterCardNumber: 'card_number',
              filterAccountNumber: 'account_number',
              filterUsreou: 'usreou',
              filterIban: 'iban',
              filterAddress: 'address',
              filterCity: 'city',
              filterState: 'state',
              filterCorrespondentBankName: 'correspondent_bank_name',
              filterCorrespondentBankSwift: 'correspondent_bank_swift',
              filterBeneficiaryBankName: 'beneficiary_bank_name',
              filterBeneficiaryBankSwift: 'beneficiary_bank_swift',
              filterCardId: 'card_id_number',
              // filterInArchive: 'in_archive',
            },
        )

        if (this.filterType !== '' && this.filterType !== 'all')
          myQuery.where('bank_type_id', this.navTabs[this.filterType].id)


        // if (this.filterDate.length > 0) {
        //   let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')
        //
        //   myQuery.whereIn(
        //       'betweenCreatedAt',
        //       date
        //   )
        // }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    }
  }
</script>

<style scoped>

</style>
